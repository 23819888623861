import React from 'react';
import {  Typography, Table, Collapse, Timeline } from 'antd';
import AdminLayout from "./AdminLayout"
import {card_content} from "./ProductCards"

const { Panel } = Collapse;
const {Title} = Typography
const Extra = ({data, setData})=>{
    const FAQ_items = [
        {
            key:"rijbewijs",
            label:"Rijbewijs",
            children:"Voor het gebruik van de paardencamionette is een rijbewijs B voldoende. Indien er met een combinatie met een trailer gereden wordt is een rijbewijs BE noodzakelijk "
        }, 
        {
            key:"Ophalen/terugbrengen",
            children:"U kan de voertuigen ophalen en terugbrengen op de locatie van 't Perhof. Perstraat 131, 9120 Haasdonk. <ul><li> Voormiddag: Van 6u tot 12u</li><li> Namiddag: Van 13u tot 19u</li><li>Volledige dag: Van 20u tot 19u</il><ul>",
            label:"Ophalen/terugbrengen"
        },  
        {
            key:"gewicht",
            label:"Maximaal laadvermogen",
            children:"Het maximale laadvermogen voor de paardencamionette is 800kg. Het is verantwoordelijkheid van de huurder dat de de camionette niet overladen wordt.<br/>Het maximale laadvermorgen voor de trailer is 2000kg."
        },   
        {
            key:"verzekering",
            label:"Verzekering",
            children:"De paardencamionette is verzekerd via P&V verzekering. <br/> Bij een ongeval in fout is er een vrijstelling van € 250 voor de verplichte BA verzekering.<p/>De paardencamionette is ook omnium verzekerd. Voor schade aan de camionette is er een vrijstelling van €1000.",
        },      
                {
            key:"waarborg",
            label:"Waarborg",
            children:"Bij de reservatie van de truck is er een waarborg verschuldigd afhankelijk van het type voertuig en de duurtijd van de huur. Deze waarborg zal terug betaald worden na het correct terugbrengen van het voertuig. "
        },   
        {
            key:"leeftijd",
            label:"Minimum Leeftijd",
            children:"De minimum leeftijd voor het huren van de paarden camionette is 21 jaar"
        },
        {
            key:"voorwaarden",
            label:"Algemene Voorwaarden",
            children:"De algemene huurvoorwaarden kan u <a href='https://drive.google.com/file/d/1EmoLow8KZvCjsLrTlYqfoQX4FaBCJEtW/view?usp=sharing' target='_blank'>hier</a>terugvinden"
        },  
                {
            label:"Voorschot en betaling",
            children:"Bij de reservatie is 40% voorschot verschuldigd via overschrijving op rekening nummer BE48 7360 6848 8027. Het saldo van de waarborg  + huurbedrag is verschuldigd bij het afhalen van de truck."
        },  
        ]
    const HUUR_timeline=[
        {
            key:"1",
            text:"Selecteer een periode en voertuig"
        },
        {
            key:"2",
            text:"vul u gegevens in"
        },{
            key:"6",
            text:"Ontvang binnen de 24u een huur bevesting via email."
        }
        
        ,{
            key:"3",
            text:"Betaal het voorschot op "
        },
        {
            key:"4",
            text:"Haal voortuig af te Perstraat 131, 9120 Haasdonk "
        }
        
        
        
        ]
        
    const columns=[
          {
            title: 'Periode',
            dataIndex: 'title',
            key: 'title',
          },
        {
            title: 'Huurprijs',
            dataIndex: 'prijs',
            key: 'prijs',
            render:(item)=>(<div>€ {item}</div>) 
          },
          {
            title: 'Inbegrepen Kilometers',
            dataIndex: 'kilometers',
            key: 'km',
            render:(item)=>(<div> {item} km</div>) 
          },{
            title: 'Waarborg',
            dataIndex: 'waarborg',
            key: 'waarborg',
            render:(item)=>(<div>€  {item}</div>) 
          },
        ]
        return(
    <AdminLayout
        title="Nuttige Info"
        >
    <div>
    <Title level={3}>Tarieven</Title>

    <Table dataSource={card_content} columns={columns}         pagination={false} 
/>
    Prijzen zijn inclusief BTW
        </div>
    <div>
    <Title level={3}>FAQ </Title>
        <Collapse items={FAQ_items} bordered={false} >
            {FAQ_items.map(item=>{
                return(
        <Panel header={item.label} key={item.key}>
              <div dangerouslySetInnerHTML={{ __html: item.children}} />
        </Panel>
                )
            })}
        
        </Collapse>
    </div>
    </AdminLayout>
        )
}


export default Extra