import React, {useContext} from 'react';
import { Layout, Menu, PageHeader, Switch } from 'antd';
import CustomLayout from '../../../components/Layout'
import {Link} from 'react-router-dom'
import {MyContext} from '../../../App'
import { withRouter } from "react-router";

const { Sider, Content } = Layout;



const AdminLayout =(props)=>{
    const { state, dispatch } = useContext(MyContext); 
    console.log(props)
    return(
        <CustomLayout>
        <Layout>
        <Sider style={{background: '#fff'}}>
        <Menu
            selectedKeys={[props.match.path]}>
            <Menu.Item key='/admin/kalender/paarden'><Link to='/admin/kalender/paarden'>Paarden</Link></Menu.Item>
            <Menu.Item key='/admin/kalender/lesgevers'><Link to='/admin/kalender/lesgevers'>Lesgevers</Link></Menu.Item>
            <Menu.Item key='/admin/kalender/leerling'><Link to='/admin/kalender/leerling'>Leerling</Link></Menu.Item>

            <hr />

            <Menu.ItemGroup title="Admin">
              <Menu.Item key='/admin/kalender/tabel'><Link to='/admin/kalender/tabel'>Agenda</Link></Menu.Item>
              <Menu.Item key='/admin/kalender/afrekening'><Link to='/admin/kalender/afrekening'>Afrekening</Link></Menu.Item>
              <Menu.Item key='/admin/kalender/LessenPerLesgever'><Link to='/admin/kalender/LessenPerLesgever'>Totalen</Link></Menu.Item>
              <Menu.Item key='/admin/kalender/kamp'><Link to='/admin/kalender/kamp'>Kamp</Link></Menu.Item>
              <Menu.Item key='/admin/kalender/gsport'><Link to='/admin/kalender/gsport'>G-sport</Link></Menu.Item>

            </Menu.ItemGroup>
        </Menu>
        </Sider>
            <Content>
            <PageHeader title={props.title}
                extra={props.extra}/>
                {props.children}
            </Content>
        </Layout>
        </CustomLayout>
        
        )
}

export default withRouter(AdminLayout)