import React, {useContext} from 'react';

import {Layout, Spin, Alert, Menu} from 'antd'
import {UserOutlined} from '@ant-design/icons'
import { useQuery} from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import  {Link, withRouter} from 'react-router-dom'
import {MyContext} from '../App'

const { Header, Content } = Layout;

export const IS_LOGGED_IN= gql`
  query{
	isLoggedIn @client(always: true)
    me {
      isSuperuser
        id
        firstName
      }
    }
`
const Main = (props)=>{
    const {data, loading, error} = useQuery(IS_LOGGED_IN)
    const { state, dispatch } = useContext(MyContext); 
    console.log(data)
    if(loading) return <Spin />
     return (
        <Layout>
            <Header>
                    <Menu theme="dark" mode="horizontal" selectedKeys={[props.match.path]}>
                    {!data.isLoggedIn && <Menu.Item key="/login"><Link to="/login">Login</Link></Menu.Item>}
                    {data.isLoggedIn && <Menu.Item key="logout" onClick={()=>{
                                localStorage.removeItem('token')
                                props.history.push('/')
                        }}>
                            Logout
                        </Menu.Item>}
                    <Menu.Item>
                        <Link to="/admin/paarden">Admin</Link>
                    </Menu.Item>
                    <Menu.Item>
                            <Link to="/admin/kalender">Lessen</Link>
                    </Menu.Item>

                    <Menu.Item key="3" style={{float:'right'}}><UserOutlined />{data.me && data.me.firstName}</Menu.Item>
                  </Menu>

            </Header>
            <Content>
                {error && <Alert content={error.message} />}
                {data && data.isLoggedIn}
                {props.children}
            </Content>
        </Layout>
        )
}

export default withRouter(Main)