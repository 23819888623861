import React, { useState } from 'react';
import {Modal, Form, Input, Select, Checkbox, DatePicker, TimePicker, Spin, Typography} from 'antd'

import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import moment from 'moment';
import AdminLayout from './KalenderLayout'
import { Row, Col } from 'antd';
const { Title, Text} = Typography;

const {Option} = Select
const GET_TOTALEN = 
gql`query($year:Int!){
  gsportOverview(year:$year){
    leerling{
      id
      leerling{
        firstName
        lastName
      }
    }
    aantal
  }
}
`

const LessenPerLesgever = (props)=>{
    const [year,setYear]  =  useState(moment().year())
    const {data, error, loading}=useQuery(GET_TOTALEN, {variables:{year}})
    const yearsRange = (start, stop, step) =>
    Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
    )
    const years = yearsRange(2021, moment().year(),1)
    const optionYears = years.map(item=><Option value={item}>{item}</Option>)
    const header  = <Row>
            <Col span={12}><Title level={5}>Naam</Title></Col>
            <Col span={12}><Title level={5}>Aantal</Title></Col>
      </Row>
    let rows = []
    if(!loading){
        console.log(data)
        rows =  data && data.gsportOverview.map(row=>{
            console.log(row)
             return (
                 <Row>
                      <Col span={12}>{row.leerling && row.leerling.leerling.firstName} {row.leerling.leerling.lastName}</Col>
                      <Col span={12}><Text strong={true}>{row.aantal}</Text></Col>
                </Row>
             
             )
         })    
    }
    return (
        <AdminLayout 
         title="G-sport per jaar" >
         <div style={{'minHeight':'100vh', backgroundColor:'white', padding:'20px'}} >
         <Row>
            <Col span={4}>
                <Select style={{ width: 120 }} value={year} onChange={(value)=>setYear(value)}>
                    {optionYears}

                </Select>
            </Col>
         </Row>
            {header}
             {loading && <Spin />}
             {rows} 
             <Row>
                <Col span={12}>
                    Totaal
                </Col>
                <Col span={12}>
                    {!loading && data.gsportOverview.reduce((a, item)=>a+ item.aantal,0)
                    }
                </Col>
             </Row>
            </div>
         </AdminLayout>
)}

export default LessenPerLesgever