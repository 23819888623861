import React, {useState, useRef} from 'react';
import {EditOutlined,DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons'
import { withRouter} from 'react-router-dom'
import AdminLayout from './AdminLayout'
import {Spin, Button,  Modal, Form,  Switch } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import ProTable, {  IntlProvider, enUSIntl} from '@ant-design/pro-table';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import AddFactuurItem from './AddFactuurItem'

const { confirm } = Modal;
var moment = require('moment'); 

export const GET_ALL_INVOICES = gql`
query($factuur:ID, $factuur_Isnull:Boolean){
  allFactuurItems(factuur_Isnull:$factuur_Isnull,factuur:$factuur){
    edges{
      node{
        id
        eigenaar{
          id
          username
        }
        beschrijving
        aantal
        eenheidsprijs
        btw
        totaal
        datum
        factuur {
          id
          nummer
          
        }
      }
    }
  }
}`

const Profile = (props)=>{
    const [isNull, setIsNull] = useState(true)
    const [editID, setEditID] = useState('')
    const [visible, setVisible] = useState(false)
    const actionRef = useRef();
    const {data, error, loading}=useQuery(GET_ALL_INVOICES,{ variables:{factuur_Isnull:isNull}})
    if(loading) return (<Spin />)
    const columns = [
       {
          title: 'Datum',
          dataIndex: 'datum',
          render: (value)=>{
            return value ? moment(value,'YYYY-MM-DD').format("MMM YYYY") : '-'
          }
            
        },
        {
          title: 'Eigenaar',
          dataIndex: 'eigenaar',
          render: (value, record)=>{
            return(
              <div>
              {value.username}
              </div>
              )
          }
        },
        {
          title: 'Factuur',
          dataIndex: 'factuur',
          render:(value)=>{
            return(
            <div>
              {value.nummer}
            </div>
              )
          }
          },

         {
            title:'Totaal',
            dataIndex:'totaal',
        },
        {
          title:"Acties",
          dataIndex:'id',
          render: (value, record)=>{
          return(
            <div>
            <Button onClick={()=>{
                    setEditID(value)
                    setVisible(true)
            }}><EditOutlined /></Button> 
            <Button onClick={()=>  confirm({
                  title: 'Wil je dit paard zeker verwijderen?',
                  icon: <ExclamationCircleOutlined />,
                  content: 'Paard zal verwijderd worden',
                  okText: 'Yes',
                  okType: 'danger',
                  cancelText: 'No',
                  onOk() {
                    },
                  onCancel() {
                    console.log('Cancel');
                  },
                })
            }>
            <DeleteOutlined /></Button>
            
            </div>
            )
          }
        }
        ]
    const rowData = data.allFactuurItems.edges.map(item=>
    ( {
          id:item.node.id,
          eigenaar:item.node.eigenaar,
          factuur : item.node.factuur,
          datum:item.node.datum,
          totaal: item.node.totaal,
      }
      )
      )

    return(
      <AdminLayout
        title="Factuur Items">
        <Form
          layout="inline"
          >
          {/*<Form.Item>
            <AllUserSelect 
              allowclear
              value={eigenaar}
              onChange={(value)=>refetch({variables:{isNull:false}})}
            style={{ width: 120 }}  />
          </Form.Item>*/}
          <Form.Item>
            <Switch 
              checked={isNull}
              onChange={()=>{
                setIsNull(!isNull)
                if (actionRef.current) {
                    actionRef.current.reload();
                  }
              }} /> 
          </Form.Item>
        </Form>
        {error && "Oeps er ging iets fout"}
        <IntlProvider value={enUSIntl}>
          <ProTable
        search={false}
        columns={columns}
        actionRef={actionRef}
        request={params => {return {data:rowData}}}
        rowKey="id"
        dateFormatter="string"
        toolBarRender={() => [
          <Button key="3" type="primary" onClick={()=>{setVisible(true)}}>
            <PlusOutlined />
            Voeg Factuuritem toe
          </Button>,
        ]}
      />
           <AddFactuurItem 
        visible={visible}
        record={visible && editID && data.allFactuurItems.edges.filter((item)=>{return item.node.id === editID})[0]}
        onOk={()=>{
          setVisible(false)
          setEditID('')
          if (actionRef.current) {
              actionRef.current.reload();
          }
        }
        }
        onCancel={()=>{
          setVisible(false)
          setEditID('')   
        }}
      />
      </IntlProvider>
    </AdminLayout>
        )        
        
        

}

export default withRouter(Profile)