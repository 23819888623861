import { gql } from 'apollo-boost';

export const GET_ALL_LESGEVERS = gql`
query{
	allLesgeverItems {
    edges{
      node{
        lesgever{
          id
          lastName
          firstName
        }
      }
    }
  }
}`

export const GET_ALL_ACCOMODATIE = gql`
query{
	  allPisteItems {
    edges {
      node {
        id
        beschrijving
      }
    }
  }
}`

export const GET_ALL_LEERLINGEN = gql`
query{
  allLeerlingItems {
    edges {
      node {
        id
        leerling{
          id
          firstName
          lastName
        }
      }
    }
  }
}`
  
  
export const GET_ALL_KALENDERITEMS = gql`
query allKalenderItems ($year:Float, $month:Float, $leerling:ID, $lesgever:ID, $betaald:String, $fromDate:Date, $toDate:Date){
  
allKalenderItems(year:$year, month:$month, leerling:$leerling, lesgever:$lesgever, betaald:$betaald, fromDate:$fromDate, toDate:$toDate){
  edges{
    node{
      id
      event{
        id
      	repeat
        startDate
        endDate
        lastDate
        prijs
        aantal
      }
      lesgever{
        id
        color
        lesgever{
          id
          firstName
          lastName
          
        }
        
      }
      leerling{
        id
        currentSubscription{
          lidPaardensportVlaanderen
        }
        leerling{
          id
          firstName
          lastName
        }
      }
      paard{
        id
        paard
        {
        id
        naam
        }
      }
      accomodatie{
        id
        beschrijving
      }
      afrekening{
        id
        nummer
      }
      startDate
      endDate
      betaald
      prijs
    }
  }
}

}`

export const ADD_KALENDER_EVENT = gql`
mutation($event:KalenderEventMutationInput!){
  kalenderEvent(input:$event){
    kalenderEvent{
      id
      
    }
    errors{
      field
      messages
    }
  }
}`

export const ADD_KALENDER_ITEM = gql`
mutation($event:KalenderItemMutationInput!){
  kalenderItem(input:$event){
    kalenderItem{
      id
    }
  }
}`

export const UPDATE_KALENDER_EVENT= gql`
mutation($event:KalenderEventInput){
  kalenderEventUpdate(input:$event){
    ok
  }
}
`


export const DELETE_KALENDER_ITEM_AFTER= gql`
mutation($id:ID!, $after:DateTime!){
  deleteAfterKalenderItem(id:$id, after:$after){
    ok
 
  }
}
`