import React, {useContext} from 'react';

import {Row, Col, Card, Button, Badge} from "antd"

import truck1 from '../../landing/img/truck1.jpg'
import truck2 from '../../landing/img/truck2.jpg'

const { Meta } = Card;


export const card_content=[{
        title:"Truck Voormiddag",
        key:"TVM",
        cover:<img src={truck1} />,
        bordered: true,
        description:"Gebruik 2-paards horsetruck van 6u-12u",
        prijs:70,
        waarborg:300,
        kilometers:70
    },
    {
        title:"Truck Namiddag",
        cover:<img src={truck2} />,

        key:"TNM",
        description:"Gebruik 2-paards horsetruck van 13u-19u",   
        kilometers: 70,
        waarborg:300,
        prijs:70,

    },
    
    {
        title:"Truck Volledige dag - Week",
        cover:<img src={truck1} />,
        description:"Gebruik 2-paards horsetruck voor een volledige weekdag"  ,     
        prijs:125,
        kilometers:125,
        waarborg:300,
        key: "TFD"

    },
    
    {
        title:"Truck Volledige dag - Weekend",
        cover:<img src={truck2}/>,
        description:"Gebruik 2-paards horsetruck voor een weekenddag"  ,     
        prijs:135,
        kilometers:135,
        waarborg:300,
        key: "TFWD"

    },
    {
        title:"Truck 2 dagen",
        cover:<img src={truck1}/>,
        description:"Gebruik 2-paards horsetruck voor 2 dagen"  ,     
        prijs:220,
        waarborg:500,
        key: "T2D",
        kilometers:220

    },
    {
        title:"Truck weekend",
        cover:<img src={truck2} />,
        description:"Gebruik 2-paards horsetruck voor een weekend"  ,     
        prijs:240,
        key: "TWE",
        waarborg:500,
        kilometers:240


    },
    ]
const ProductCards = ({data, setData, current, setCurrent})=>{
    

    const NextButton =()=>{
    return  (   
            <Button 
            
                type="primary" 
                disabled={data.period === null} 
                onClick={()=>setCurrent(current+1)}
                style={{
                        float:"right",
                        margin:"8px"
                }}>Next</Button>
                )
    }
    return(
        <div>
            <Row gutter={[32, 32]} justify="end">
        <Col>
            <NextButton />
        </Col>
        </Row>
        <Row gutter={[16,16]}>
        {card_content.map(item=>{
            
            return(
                <Col xs={{ span: 24}} lg={{ span: 8 }} md={{ span: 8 }}>
                    <Badge.Ribbon text={<div>€ {item.prijs}</div>}>

                    <Card 
                        onClick = {()=>setData({...data, period:item.key})}
                        cover={item.cover}
                            style={{
                              width: "100%",
                              border: data.period === item.key && "2px solid #FFC93C"
                            }}
                        hoverable={true}
                        {...item}
                        >
                        <Meta
                            title={item.title}
                            description={item.description}
                        />
                    </Card>    
                    </Badge.Ribbon>
                </Col>
            )
        })}
            
        </Row>
        <Row justify="end">
         <Col>
                <NextButton />
        </Col>
        </Row>
        <Row>
            Tarieven zijn inclusief BTW. <br />
            Voor langere periodes of andere speciale wensen gelieve het contactformulier in te vullen.
        </Row>
        </div>

        )
}
export default ProductCards