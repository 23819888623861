import React from 'react';
import { useMutation } from '@apollo/react-hooks';

import { gql } from 'apollo-boost';
import { Modal, Input, message, Form, DatePicker, InputNumber} from 'antd';
import AllUserSelect from './components/AllUsersSelect';
import AllFactuurSelect from './components/AllFactuurSelect'
import {GET_ALL_USERS} from './Users'
var moment = require('moment'); 

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ADD_FACTUUR_ITEM= gql`
mutation ($input:FactuurItemMutationInput!) {
  addFactuurItem(input:$input) {
   factuurItem{
       id
       factuur{
           id
           nummer
       }
   }
   errors{
       field
       messages
   }
  }
}`
const AddUserModal=({visible, onOk, onCancel, record, eigenaar})=>{
    console.log(eigenaar)
    const [form] = Form.useForm();
    const [addFactuurItem, {error: mutationError}]= useMutation(ADD_FACTUUR_ITEM,{
    onCompleted(...params){ 
        message.success("User succesvol toegevoegd")
        onOk()

        },
      awaitRefetchQueries:true,
      refetchQueries:[{query:GET_ALL_USERS}],
    onError(...params){
        console.log("error")
    }
    })
    const onFinish = async (values)=>{
        console.log(values)
        if(record) {values.id = record.node.id}
        values.datum = values.datum.format('YYYY-MM-DD') 
        await addFactuurItem({variables:{input:values}})
        form.resetFields();

    }
    console.log(moment())
    let defaultValues = {
        datum:  moment(),
        btw:0.21}
    if(record){
        defaultValues ={
            datum: record.node.datum ? moment(record.node.datum,'YYYY-MM-DD') : moment(),
            eigenaarID:record.node.eigenaar.id,
            beschrijving:record.node.beschrijving,
            aantal: record.node.aantal,
            eenheidsprijs:record.node.eenheidsprijs,
            btw:record.node.btw,
            factuurID: record.node.factuur && record.node.factuur.id,
        }
    }
    if(eigenaar){
        defaultValues ={
            eigenaarID:eigenaar,
            btw:0.21,
            
            }
    }
        form.setFieldsValue(defaultValues)        

    return(
        <Modal
            title="Voeg een FactuurItem toe"
            visible={visible}
            onCancel={onCancel}
            defaultValues={defaultValues}
            onOk={()=>{
                form.submit()
            }}
            >
            {mutationError && mutationError.message}
            <Form 
                form={form}
                {...layout} 
                onFinish={onFinish}>
                <Form.Item
                    label="Datum"
                    name="datum"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <DatePicker />
                </Form.Item>
                
                <Form.Item
                    label="Eigenaar"
                    name="eigenaarID"
                    rules={[{ required: true, message: 'Please input your username!' }]}

                >
                    <AllUserSelect 
                        onChange={(value)=>form.setFieldsValue({eigenaar:value})}
                        value={form.getFieldValue('eigenaarID')}
                        />                
                </Form.Item>
                <Form.Item
                    label="Beschrijving"
                    name="beschrijving"
                    rules={[{ required: true, message: 'Please input your username!' }]}

                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Aantal"
                    name="aantal"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    label="Prijs per stuk"
                    name="eenheidsprijs"

                >
                    <InputNumber />
                </Form.Item>

                <Form.Item
                    label="BTW"
                    name="btw"

                >
                    <InputNumber />
                </Form.Item>
                <Form.Item 
                    label="Factuur"
                    name="factuurID">
                        <AllFactuurSelect
                        onChange={(value)=>form.setFieldsValue({factuurID:value})}
                        value={form.getFieldValue('factuurID')}
                        eigenaar = {form.getFieldValue('eigenaar')}
                        />
                </Form.Item>
                    
                    </Form>       
        </Modal>
        )
}

export default AddUserModal