import React, {useState, useRef} from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {Spin, Button, Input, Modal, message} from 'antd'
import {EditOutlined,DeleteOutlined, ExclamationCircleOutlined, CheckOutlined, DownloadOutlined, PlusOutlined} from '@ant-design/icons'
import LeerlingModal from './LeerlingModal'
import SubscriptionModal from './SubcriptionModel'
import { gql } from 'apollo-boost';

import ProTable, {  IntlProvider, enUSIntl} from '@ant-design/pro-table';

import AdminLayout from './KalenderLayout'
const { confirm } = Modal;

export const GET_ALL_LEERLINGEN = gql`
query{
  allLeerlingItems{
    edges{
      node{
        id
        isGSport
        currentSubscription{
        id
          seizoen{
            id
            end
            start
          }
          lidPaardensportVlaanderen
          lidgeld
        }
        leerling{
          id
          firstName
          lastName
        }
        naamOuder
        bridleId
      }
    }
  }
}
`
const PVSUBSCRIPTION= gql`mutation($id:ID!, $lidPaardensportVlaanderen:Boolean!){
  setPaardensportVlaanderenMutation(id:$id, lidPaardensportVlaanderen:$lidPaardensportVlaanderen){
    ok
  }
}`
const Paarden = (props)=>{
    const {data, error, loading}=useQuery(GET_ALL_LEERLINGEN)
    const [addPV]= useMutation(PVSUBSCRIPTION,{
    onCompleted(...params){ 
        message.success("subscription succesvol toegevoegd")
        },
      awaitRefetchQueries:true,
      refetchQueries:[{query:GET_ALL_LEERLINGEN}],
    })
    const [subscriptionVisible, setSubscriptionVisible]=useState(false)
    const [search, setSearch] = useState(null)
    const [visible, setVisible] = useState(false)
    const [editID, setEditID]= useState(null)
    const actionRef = useRef();
    const columns = [
       {
          title: 'naam',
          dataIndex: 'id',
          render: (value, record)=>{
            return(record.firstName + " "+ record.lastName)
          },
          sorter: (a,b)=> a.firstName  < b.firstName,
          filterSearch:true,
          onFilter:(value, record) =>record.firstName.includes(value)
            
        },
       {
          title: 'Lid Paardensport Vlaanderen',
          dataIndex: 'subscription',
          render:(value, record)=>{
              return(value.lidPaardensportVlaanderen ? <CheckOutlined /> : value.seizoen && <Button onClick={()=>addPV({variables:{id:value.id, lidPaardensportVlaanderen:true}})}>Bevestig lidmaatschap</Button>)
          }, 
          filterMode:"menu",
          onFilter: (value, record) => record.subscription.lidPaardensportVlaanderen ===value, 
          sorter:(a,b) =>{
            let c = a.subscription ? a.subscription.lidPaardensportVlaanderen : false
            let d = b.subscription ? b.subscription.lidPaardensportVlaanderen : false
            return (c -d)
          } 
        },
        {
          title: "Lidgeld ",
          dataIndex: "subscription",
          render:(value, record)=>{
                return("€ "+ value.lidgeld ? value.lidgeld : 0)
          }, 
          sorter:(a,b) =>{
            let c = a.subscription ? a.subscription.lidgeld : 0
            let d = b.subscription ? b.subscription.lidgeld : 0
            return (c -d)
          } 
        },
        {
          title:'G-sporter',
          dataIndex:"isGSport",
          render:(value)=>{
            return(value && <CheckOutlined />)
          },
        sorter:(a,b) =>a.isGSport - b.isGSport
        },
        {
          title:"Acties",
          dataIndex:'id',
          render: (value, record)=>{
          return(
            <div>
            <Button  onClick={()=>{
                setEditID(value)
                setVisible(true)
              
            }}><EditOutlined/></Button> 
             <Button
                onClick={()=>{
                setSubscriptionVisible(true)
                setEditID(value)
                }
                }
              >
             
              <PlusOutlined  />
            </Button>
            
            <Button disabled onClick={()=>  confirm({
                  title: 'Wil je dit paard zeker verwijderen?',
                  icon: <ExclamationCircleOutlined />,
                  content: 'Paard zal verwijderd worden',
                  okText: 'Yes',
                  okType: 'danger',
                  cancelText: 'No',
                  onOk() {
                    },
                  onCancel() {
                    console.log('Cancel');
                  },
                })
            }>
            <DeleteOutlined /></Button>
            
            </div>
            )
          }
        },

        
        ]
    let rowData = !loading && data.allLeerlingItems.edges.map(item=>
    ( {
          id:item.node.id,
          firstName:item.node.leerling.firstName,
          lastName:item.node.leerling.lastName,
          bridleId:item.node.bridleId,
          naamOuder:item.node.naamOuder,
          subscription: item.node.currentSubscription,
          isGSport:item.node.isGSport
      }
      )
      )
      if (!loading && actionRef.current) {
        actionRef.current.reload();
      }
    return(
        <AdminLayout title= "Leerlingen">
               {error && "An error occured"}
      <IntlProvider value={enUSIntl}>
      {loading && <Spin />}
      <ProTable
        search={false}
        columns={columns}
        actionRef={actionRef}
        //dataSource={rowData}
        request={params => {return {data:rowData}}}
        rowKey="id"
        dateFormatter="string"
        toolBarRender={() => [
        <Input 
        value={search}
        onChange={(e)=>{
          e.preventDefault();
          setSearch(e.target.value)
        }
        }
        placeholder="Zoeken"/> 
     ,
      <Button onClick={()=>{
          rowData= rowData.filter(record=>{
            return(record.firstName.toLowerCase().includes(search.toLowerCase()) || record.lastName.toLowerCase().includes(search.toLowerCase()))
          })
          console.log(rowData)
          actionRef.current.reload();

      }}
      >
        Search
      </Button>,
          <Button key="3" type="primary" onClick={()=>{setVisible(true)}}>
            <PlusOutlined />
            Voeg een leerling toe
          </Button>,
        ]}
      />     
      </IntlProvider>
         <LeerlingModal 
          visible={visible}
          record = {editID && data.allLeerlingItems.edges.filter(item=> item.node.id === editID)[0]}
          onCancel={()=>{
            setVisible(false)
            setEditID(null)}
          }
          onOk={()=>{
            setVisible(false)
            setEditID(null)

            if (actionRef.current) {
              actionRef.current.reload();
            }

        }
        }          
        />  
        <SubscriptionModal
          visible={subscriptionVisible}
          leerling = {editID}
          onCancel={()=>{
            setSubscriptionVisible(false)
            setEditID(null)

          }}
          onOk={()=>{
            setSubscriptionVisible(false)
            setEditID(null)

            if (actionRef.current) {
              actionRef.current.reload();
            }
          }}
        />
        </AdminLayout>

        )
}

export default Paarden