import React  from 'react';
import { Timeline, Card } from 'antd';

const HooiTimeline = ({data})=>{
    const hooiData = data.edges.map(item=>{
        let tag=''
        if(item.node.aantal > 0){
            tag = item.node.aantal + ' pakken ' + item.node.soortHooi.toLowerCase() + ' gekocht'
        }else{
            tag = Math.abs(item.node.aantal) +  ' pak ' + item.node.soortHooi.toLowerCase() +' verbuikt voor ' + item.node.kudde.toLowerCase()
        }
        return(
            <Timeline.Item label={item.node.datum} color={item.node.aantal > 0 ? 'green' : 'red'}>
                {tag}
            </Timeline.Item>
            )
    })
    return (
        <Timeline mode='left'>
            {hooiData}
        </Timeline> 
        )
}

export default HooiTimeline
