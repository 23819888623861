import React, {useState} from 'react';
import { Row, Col, Form, Input, Select,  Button } from 'antd';
import {  useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

const { Option } = Select;


const { TextArea } = Input;
const layout = {
  labelCol: { offset:2, span: 4 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 18, span: 4 },
};
export const SEND_EMAIL = gql`
mutation($email:String!, $naam:String!, $question:String!, $topic:String, $phone:String){
  sendEmail(input:{naam:$naam,email:$email, phone:$phone,topic:$topic, question:$question}){
    email{
      naam
      email
      id
      
    }
    errors{
      field
      messages
    }
  }
}`
const Content3 =(props)=>{
    const [form] = Form.useForm();
    const [sendMail] = useMutation(SEND_EMAIL)
    const [isSend, setIsSend] = useState(false)
    const onFinish = (values)=>{
      sendMail({variables:values})
      form.resetFields()
      setIsSend(true)
    }
    return (
      <div id="Content3_0"  className= 'home-page-wrapper content3-wrapper'>
        <div className= 'home-page content3' >
          <div className="page2-title">
            Contact
          </div>
          <Row gutter={[0,16]}>
            <Col span={23} offset={1}>
            Indien u ons wenst te contacteren voor een bezoek kan u steeds uw gegevens achterlaten via het contactformulier of telefonisch via +32 470 10 50 23
            </Col>
          </Row>
            {isSend ? "Bedankt voor u bericht" :(<Form 
              {...layout}
              form={form}
              onFinish={onFinish}
              >
              <Form.Item name="naam" label="Naam" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="phone" label="Telefoon nummer">
                <Input />
              </Form.Item>
              <Form.Item name="topic" label="Onderwerp">
                <Select>
                  <Option value="stalling">Pensionstalling </Option>
                  <Option value="lessen">Ponylessen</Option>
                  <Option value="kampen">Ponykampen</Option>
                  <Option value="kampen">Verhuur paardencamionette</Option>

                </Select>
              </Form.Item>
              <Form.Item name="question" label="Uw bericht" rules={[{ required: true }]}>
                <TextArea placeholder="Wat is uw bericht" />
              </Form.Item>
                    <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit">
                Versturen
              </Button>
            </Form.Item>
            </Form>
          )}
        </div>
      </div>
    );
  }


export default Content3;
