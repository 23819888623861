import {
  AutoComplete,
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
  Space,
} from 'antd';
import React, { useState } from 'react';

const {Title} = Typography
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};


const App = ({data, setData,  current, setCurrent}) => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
      console.log('Received values of form: ', values);
       setData({...data, gegevens:values })
      setCurrent(current+1)

  };
  return (
    <div>
    <Title level={2}>Vul hieronder u gegevens in</Title>
    <p>We zijn er bijna, We hebben gewoon nog een paar gegevens nodig:</p>
    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{
          }}
      scrollToFirstError
    >
      <Form.Item
        name="name"
        label="Naam"
        rules={[
          {
            required: true,
            message: 'Gelieve u naam op te geven!',
          },
        ]}
      >
        <Input />
      </Form.Item>
      
      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: 'email',
            message: 'Gelieve een correct email adres op te geven!',
          },
          {
            required: true,
            message: 'Gelieve u email adres op te geven',
          },
        ]}
      >
        <Input />
        
      </Form.Item>

      <Form.Item
        name="straat"
        label="Straat + Nummer"
        rules={[
          {
            required: true,
            message: 'Geef u adres op',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="gemeente"
        label="Postcode + Gemeente"
        rules={[
          {
            required: true,
            message: 'Please select your habitual residence!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="phone"
        label="Telefoon nummer"
        rules={[
          {
            required: true,
            message: 'Please input your phone number!',
          },
        ]}
      >
        <Input    />
      </Form.Item>

      <Form.Item
        name="rijbewijs"
        label="Rijbewijs nummer"
        rules={[
          {
            required: true,
            message: 'Geef u rijbewijs nummer!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="extra"
        label="Geef Extra info op"

      >
        <Input.TextArea showCount maxLength={2000} />
      </Form.Item>


        <Space wrap  style={{float:"right"}}> 
             <Button
                 onClick={()=>{
                    setCurrent(current-1)

                 }
              }
             >
                 Previous
             </Button>
              <Button 
                type="primary" 
                disabled={data.fromDate === null} 
                onClick={()=>{
                  form.submit()  
                }
                }
               >Next</Button>
          </Space>
    </Form>
    </div>
  );
};
export default App;