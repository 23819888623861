import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Modal, Input, message, Form, DatePicker, InputNumber} from 'antd';
import AllUserSelect from './components/AllUsersSelect';
import {GET_ALL_PAARDEN} from './Paarden'
import {GET_ALL_USERS} from './Users'

var moment = require('moment'); 
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ADD_FACTUUR= gql`
mutation($datum:Date!,$eigenaar:ID!){
  createInvoice(datum:$datum, eigenaar:$eigenaar){
    factuur{
      id
    }
    
  }
}`

const AddPaardModal=({visible, onOk, onCancel, record, eigenaar, refetchQueries})=>{
    const [form] = Form.useForm();
    const [addFactuur]= useMutation(ADD_FACTUUR,{
    onCompleted(...params){ 
        message.success("Paard succesvol toegevoegd")
        },
      awaitRefetchQueries:true,
      refetchQueries:refetchQueries,
    })

    const onFinish = async (values)=>{
        values.datum =  values.datum && values.datum.format('YYYY-MM-DD')    

        await addFactuur({variables:values})
        onOk()
        form.resetFields();

    }
    return(
        <Modal
            title="Voeg een factuur toe"
            visible={visible}
            onCancel={()=>{
                form.resetFields()
                onCancel()
                
            }}
            onOk={()=>{
                form.submit()
            }}
            >
            <Form 
                form={form}
                {...layout} 
                onFinish={onFinish}>
                <Form.Item
                    label="Datum"
                    name="datum"
                    rules={[{ required: true, message: 'Please input a date' }]}
                
                >
                    <DatePicker />
                </Form.Item>
                
                <Form.Item
                    label="Eigenaar"
                    name="eigenaar"
                    rules={[{ required: true, message: 'Geef de eigenaar van het paard!' }]}
                
                >
                    <AllUserSelect 
                        onChange={(value)=>form.setFieldsValue({eigenaar:value})}
                        value={form.getFieldValue('eigenaar')}
                        />
                </Form.Item>
             
            </Form>       
        </Modal>
        )
}

export default AddPaardModal