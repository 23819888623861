import React from 'react';

import { useQuery} from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Select, Spin } from 'antd';

const { Option } = Select;
const ALL_USERS=gql`
query($leerling:ID){
  allAfrekeningItems(leerling:$leerling){
    edges{
      node{
        id
        datum
        nummer
      }
    }
  }
}`

const UserSelect = (props)=>{
    const {data, loading}= useQuery(ALL_USERS, {variables:{leerling:props.leerling}})
    if(loading) return (<Spin />)
    const options = data.allAfrekeningItems.edges.map(afrekening =>(
        <Option key={afrekening.node.id} value={afrekening.node.id}>{afrekening.node.nummer}</Option>
        )
            
    )
    return(
        <Select 
            {...props}
            showSearch
            optionFilterProp="children"
            value={props.value}
            onChange={(value)=>props.onChange(value)}>
            {options}
        </Select>
        )
}

export default UserSelect