import React, {useState, useContext}from 'react';

import { withRouter} from 'react-router-dom'
import Layout from '../components/Layout'
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { IS_LOGGED_IN} from '../components/Layout'
import SocialButton from './SocialButton'
import {MyContext} from '../App'
const GET_USER = gql`
mutation($accessToken:String!) {
  socialAuth(provider: "google-oauth2", accessToken: $accessToken) {
    social {
      uid
    }
    token
  }
}`



const Profile = (props)=>{
  const [user, setUser]= useState('')
  const {data:userData}= useQuery(IS_LOGGED_IN)
  const { state, dispatch } = useContext(MyContext); 
  const [getUser] = useMutation(GET_USER, {
      awaitRefetchQueries:true,
      refetchQueries:[{query:IS_LOGGED_IN}],
      update(store, { data: { socialAuth } }){
            localStorage.setItem('token', socialAuth.token)
            store.writeData({data:{isLoggedIn:true}})
            dispatch({type:'field', fieldName:'userId',payload:socialAuth.social.uid})
        },
      onCompleted(...params){
         // localStorage.setItem('token',params[0].socialAuth.token);
          props.history.push('/admin/paarden')

      }
    })
  const handleSocialLogin = (user) => {
      localStorage.removeItem('token')
      setUser(user)
      getUser({variables:{accessToken:user._token.accessToken}})
    }
   
  const handleSocialLoginFailure = (err) => {
    console.error(err)
  }
  if (userData && userData.isLoggedIn){
    props.history.push('/profile')
  }
    return(
      <Layout>
            <SocialButton
          provider='google'
          appId='395265236756-vqofmnd82rlkam9juobrecq73t89rs54.apps.googleusercontent.com'
          onLoginSuccess={handleSocialLogin}
          onLoginFailure={handleSocialLoginFailure}
        >
              Login with Google
        </SocialButton>

    </Layout>
        )        
}

export default withRouter(Profile)