import React,{useState, useContext, useRef} from 'react';
import {Spin, Switch} from 'antd'
import {CheckOutlined} from '@ant-design/icons'

import moment from 'moment'
import {GET_ALL_KALENDERITEMS} from './query'
import { useQuery, useMutation } from '@apollo/react-hooks';
import AdminLayout from './KalenderLayout'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import EventModal from './EventModal'
import OpenChoices from './OpenChoices'
import Kalender from './Kalender'
import TableView from './TableView'
import {MyContext} from '../../../App'
import FilterForm from './FilterForm'
import CsvDownload from 'react-json-to-csv'
// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
import { ADD_KALENDER_EVENT, ADD_KALENDER_ITEM, UPDATE_KALENDER_EVENT} from './query'

const MyCalendar = props => {
  //const [date, setDate] = useState(new Date())
  const [slot, setSlot] = useState(null)
  const { state, dispatch } = useContext(MyContext); 
  const [date, setCalenderDate] = useState(moment())
  const fromDate = moment(date).startOf("month").startOf("week")
  const toDate = moment(date).endOf("month").endOf("week").add(1, "day")  
  console.log(fromDate)
  console.log(date)
  console.log(toDate)
  //console.log(today.endOf('week').toString())
  //const [filterFields, setFilterFields] = useState({lesgever:null, leerling:null, year: new Date().getFullYear(), betaald:'', month:new Date().getMonth()+1})
  
  const [filterFields, setFilterFields] = useState({lesgever:null, leerling:null, fromDate: fromDate.format("YYYY-MM-DD"), toDate:toDate.format("YYYY-MM-DD"), betaald:''})
  
  //const {data:schedData, loading, error, refetch} = useQuery(GET_ALL_KALENDERITEMS, {variables:...filterFields})
  //const [filterFields, setFilterFields] = useState({lesgever:null, leerling:null, startDate: today.startOf('week').toISOString(), endDate:today.endOf('week').toISOString(), betaald:'', month:new Date().getMonth()+1})
    const actionRef = useRef();

  const {data:schedData, loading, error, refetch} = useQuery(GET_ALL_KALENDERITEMS, {variables:{...filterFields},
      onCompleted(...params){ 
        if (actionRef.current) {
          actionRef.current.reload();
        }
      }
  })

  const [modalVisible, setModalVisible] = useState(false)
  const [showRepeatPanel, setShowRepeatPanel] = useState(false)
  const [event, setEvent] = useState(null)
  const [choicesVisible, setChoicesVisible]=useState(false)
  const closeModal = ()=>{
    setShowRepeatPanel (false)
    setEvent(null)
    setSlot(null)
    setChoicesVisible(false)
    setModalVisible(false)
    refetch()
  }
  const setDate = (date)=>{
          setCalenderDate(date)

          const fromDate = moment(date).startOf("month").startOf("week")
          const toDate = moment(date).endOf("month").endOf("week").add(1,"day")
          setFilterFields({...filterFields, 'fromDate':fromDate.format("YYYY-MM-DD"), 'toDate':toDate.format("YYYY-MM-DD")})
    
  }
    
  
  const data = !loading ? schedData.allKalenderItems.edges.map(item=>{
  return(
        { 
    id: item.node.id,
    startDate: new Date(item.node.startDate),
    endDate: new Date(item.node.endDate),
    lesgever: item.node.lesgever,
    leerling: item.node.leerling,
    paard: item.node.paard,
    title: item.node.lesgever && item.node.lesgever.lesgever.firstName +" - " + item.node.leerling.leerling.firstName + ((item.node.leerling.currentSubscription && item.node.leerling.currentSubscription.lidPaardensportVlaanderen) ? " (ok)"  : ""),
    resource: item.node.lesgever && item.node.lesgever.id,
    location:'Binnenpiste',
    betaald:item.node.betaald,
    prijs:item.node.prijs,
  }
      )
  }) : []
  console.log(data)
  const downLoadData = data.map(item=>{
    return({
      start:item.startDate.toISOString(),
      einde:item.endDate.toISOString(),
      lesgever: item.lesgever && item.lesgever.lesgever.firstName + " " + item.lesgever.lesgever.lastName,
      leerling:item.leerling && item.leerling.leerling.firstName + " " + item.leerling.leerling.lastName,
      
    })
  })
return (
  <AdminLayout
        title="Kalender"
        extra={ [<Switch 
                    onChange = {()=>dispatch({'type':'field', 'fieldName':'isCalendar', 'payload':!state.isCalendar})}
                    checkedChildren="Kalender" 
                    unCheckedChildren="Tabel"
                    checked={state.isCalendar}
                    />,   <CsvDownload data={downLoadData} />]}>
    <FilterForm 
      value={filterFields}
      onChange={(filterFields)=>setFilterFields(filterFields)}
    />
    {loading && <Spin />}
    <div heigth="400px">
{error && error.message}
{state.isCalendar ? <Kalender 
 setDate= {(date)=>{setDate(date)}}
 data={data}
 date={date}
 setModalVisible={setModalVisible}
 setShowRepeatPanel={setShowRepeatPanel}
 setChoicesVisible={setChoicesVisible}
 setSlot={setSlot}
 setEvent= {setEvent}
 /> : 
 <TableView
    data={data}
    date={date}
    actionRef={actionRef}
    setDate= {(date)=> {
    setDate(date)
      
    }}
    setModalVisible={setModalVisible}
    setChoicesVisible={setChoicesVisible}
    setEvent= {setEvent}

  /> }
  

<OpenChoices 
  refetch={refetch}
  visible={choicesVisible}
  onEditItem = {()=>{
    setModalVisible(true)
    }
  }
  onEditSeries = {()=>{
      setModalVisible(true); 
      setChoicesVisible(false)
      setShowRepeatPanel(true)
     }}
  onCancel={()=>{
    closeModal()
    
  }}
  event= {event && schedData.allKalenderItems.edges.filter(item=> item.node.id === event)[0]}

  />
<EventModal 
  visible={modalVisible}
  mutation={showRepeatPanel ? (event ? UPDATE_KALENDER_EVENT : ADD_KALENDER_EVENT) : ADD_KALENDER_ITEM}
  showRepeatPanel={showRepeatPanel}
  onCancel = {()=>{
      closeModal()
  }}
  onOk =  {()=>{
        closeModal()
  }}
  slot = {slot}
  event= {event && schedData.allKalenderItems.edges.filter(item=> item.node.id === event)[0]}
  />
</div>
</AdminLayout>
  )}
  
export default MyCalendar