import React, {useState, useRef} from 'react';

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
const Calender = ({setDate, data, date, setModalVisible, setShowRepeatPanel, setChoicesVisible, setSlot, setEvent})=>{

    const localizer = momentLocalizer(moment)
    moment.locale('nl', {
        week: {
            dow: 1,
            doy: 1,
        },
    });
      const formats={
        'eventTimeRangeFormat': " "
      }
      const  eventPropGetter=(event, start, end, isSelected) => {
          let newStyle = {
            backgroundColor: event.lesgever ? event.lesgever.color : 'blue',
            color: 'black',
            borderRadius: "5px",
            border: "None"
          };
    
          if (event.isMine){
            newStyle.backgroundColor = "lightgreen"
          }
    
          return {
            className: "",
            style: newStyle
          };
        }
    
    const onSelectSlot=({start, end})=>{
      setSlot([start, end])
      setModalVisible(true)
      setShowRepeatPanel(true)
      
    }
    const onSelectEvent =(event)=>{
      setChoicesVisible(true)
      setEvent (event.id)
    }
return(
    <Calendar
          localizer={localizer}
          onNavigate={(newDate)=>{
            setDate(newDate)
          }}
          events={data}
          date={date}
          selectable={true}
          defaultView='week'
          startAccessor="startDate"
          endAccessor="endDate"
          views={['week','month','day']}
          //resources={['TGVzZ2V2ZXJJdGVtOjE=', 'TGVzZ2V2ZXJJdGVtOjI=']}
          formats={formats}
          eventPropGetter={eventPropGetter}
          style={{ height: '100vh' }}
          min={new Date(2017, 10, 0,8 , 0, 0)}
          max={new Date(2017, 10, 0, 22, 0, 0)} 
          onSelectEvent={event => onSelectEvent(event) }
          onSelectSlot={onSelectSlot}
        />
        
    )
  }
export default Calender