import React from 'react';

import {  Select, Form, InputNumber, DatePicker } from 'antd';

const { Option } = Select;
const RepeatPanel =({disable})=>

{
    return (
        <div>
        <Form.Item
            label="Frequency"
            name="repeat"
            rules={[{ required: true, message: 'Selecteer een datum' }]}
         >
            <Select 
            disabled={disable}>
                <Option value="Wekelijks">Wekelijks</Option>
                <Option value="Twee-wekelijks">Twee-wekelijks</Option>
                <Option value="Maandelijks">Maandelijks</Option>

            </Select>
        </Form.Item>
        <Form.Item
         disabled={disable}
            label="Laatste dag"
            name="lastDate"
            rules={[{ required: false, message: 'Selecteer een datum' }]}
         >
            <DatePicker
             disable={disable}/>
        </Form.Item>
        <Form.Item
            label="Aantal"
            name="aantal"
            rules={[{ required: false, message: 'Selecteer een datum' }]}
         >
            <InputNumber
             disabled={disable}
            />

        </Form.Item>
        </div>
        )
}

export default RepeatPanel