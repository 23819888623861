import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Modal, Input, message, Form, DatePicker} from 'antd';
import {GET_ALL_AFREKENING} from './Afrekening'
import LeerlingSelect from '../components/LeerlingSelect'

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ADD_AFREKENING= gql`
mutation ($input: AfrekeningInput) {
  afrekening(input: $input) {
    afrekening {
      id
    }
  }
}`

const LeerlingModal=({visible, onOk, onCancel, record})=>{
    const [form] = Form.useForm();
    const [addLeerling]= useMutation(ADD_AFREKENING,{
    onCompleted(...params){ 
        message.success("Afrekening succesvol toegevoegd")
        },
      awaitRefetchQueries:true,
      refetchQueries:[{query:GET_ALL_AFREKENING}],
    })

    const onFinish = async (values)=>{
        if(record) {values.id = record.node.id}
        await addLeerling({variables:values})
        onOk()
        form.resetFields();

    }
    let defaultValues = {}

    return(
        <Modal
            title="Voeg een leerling toe"
            visible={visible}
            onCancel={()=>{
                form.resetFields()
                onCancel()
                
            }}
            onOk={()=>{
                form.submit()
            }}
            >
            <Form 
                form={form}
                {...layout} 
                onFinish={onFinish}>
                <Form.Item
                    label="Datum "
                    name="datum"
                    rules={[{ required: true, message: 'datum' }]}
                
                >
                    <DatePicker />
                </Form.Item>
                
                <Form.Item
                    label="Leerling"
                    name="leerling"
                    rules={[{ required: true, message: 'Geef de eigenaar van het paard!' }]}
                
                >
                    <LeerlingSelect 
                        onChange={(value)=>{console.log(value); form.setFieldsValue({leerling:value})}}
                        value={form.getFieldValue('leerling')}
                        /> 
                
                </Form.Item>
       </Form>       
        </Modal>
        )
}

export default LeerlingModal