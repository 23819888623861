import React, {useState, useRef} from 'react';
import {DownloadOutlined, CheckCircleOutlined, MailOutlined, EuroCircleOutlined} from '@ant-design/icons'
import { withRouter} from 'react-router-dom'
import AdminLayout from './KalenderLayout'
import { PlusOutlined } from '@ant-design/icons';
import {Spin, Button, message,  Form, Select, Checkbox, Table} from 'antd'
import ProTable, {  IntlProvider, enUSIntl} from '@ant-design/pro-table';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import LeerlingSelect from '../components/LeerlingSelect'
import AfrekeningModal from './AfrekeningModal'
const Option = Select
var moment = require('moment'); 

export const GET_ALL_AFREKENING = gql`
query($leerling:ID, $month:Float, $year:Float, $notState:String){
	allAfrekeningItems(month:$month, year:$year, leerling:$leerling, notState:$notState){
    edges{
      node{
        id
        nummer
        datum
        send
        state
        totaal
        leerling{
          id
          leerling{
            id
            firstName
            lastName
          }
        }
      }
    }
  }
}`

export const CREATE_ALL_AFREKENING = gql`
mutation{
  createAllAfrekening{
    ok
  }
}`

export const PUBLISH_AFREKENIG = gql`
mutation($id:ID!){
  publishAfrekening(id:$id){
    afrekening{
      id
    }
  }
}
`
// nog aan te passen
export const SEND_AFREKENING = gql`
mutation($id:ID!){
  sendAfrekening(id:$id){
    afrekening{
      id
    }
  }
}
`

// nog aan te passen
export const PAID_INVOICE = gql`
mutation ($id: ID!) {
  betaalAfrekening(id: $id) {
    afrekening {
      id
    }
  }
}`

const Profile = (props)=>{
    const [month, setMonth]= useState(null)
    const [year, setYear]= useState(null)
    const [nietBetaald, setNietBetaald]= useState(true)
    const [visible, setVisible]= useState(false)
    const [leerling, setLeerling]= useState('')
    const {data, error, loading}=useQuery(GET_ALL_AFREKENING,{ 
          variables:{month:month, year:year, leerling:leerling, notState:nietBetaald ? "paid" : ""},
          onCompleted(...params){
            if (actionRef.current) {
                actionRef.current.reload();
              }
          }    
      
    })
    const actionRef = useRef();
    
    const [createInvoices] = useMutation(CREATE_ALL_AFREKENING,{
    onCompleted(...params){ 
      if (actionRef.current) {
        actionRef.current.reload();
      }
        message.success("Invoices zijn aangemaakt")
        },
      awaitRefetchQueries:true,
      refetchQueries:[{query:GET_ALL_AFREKENING}],
    })
    const [publishFactuur] = useMutation(PUBLISH_AFREKENIG,{
    onCompleted(...params){ 
      if (actionRef.current) {
        actionRef.current.reload();
      }
        message.success("Factuur is klaar")
        },
      awaitRefetchQueries:true,
      refetchQueries:[{query:GET_ALL_AFREKENING,  variables:{month:month, year:year, leerling:leerling}}],
    })
    const [sendFactuur] = useMutation(SEND_AFREKENING,{
    onCompleted(...params){ 
      if (actionRef.current) {
        actionRef.current.reload();
      }
        message.success("Factuur is klaar")
        },
      awaitRefetchQueries:true,
      refetchQueries:[{query:GET_ALL_AFREKENING,  variables:{month:month, year:year, leerling:leerling}}],
    })


    const [paidAfrekening] = useMutation(PAID_INVOICE,{
    onCompleted(...params){ 
      if (actionRef.current) {
        actionRef.current.reload();
      }
        message.success("Factuur is klaar")
        },
      awaitRefetchQueries:true,
      refetchQueries:[{query:GET_ALL_AFREKENING,  variables:{month:month, year:year, leerling:leerling}}],
    })

    const columns = [
       {
          title: 'Afrekening',
          dataIndex: 'nummer',
          sorter: (a, b) =>  a.nummer < b.nummer ? -1 : 1,
            
        },
        {
          title: 'Leerling',
          dataIndex: 'leerling',
          sorter: (a, b) =>  a.leerling.leerling.firstName < b.leerling.leerling.firstName ? -1 : 1,
          render: (value, record)=>{
            return(
              <div>
              {value.leerling.firstName}
              </div>
              )
          }
        },
        {
          title: 'Datum',
          dataIndex: 'datum',
          
          render: (value)=>{
            return value ? moment(value,'YYYY-MM-DD').format("MMM YYYY") : '-'
          }
        },
        {
            title:'Status',
            dataIndex:'state',
        },
         {
            title:'Totaal',
            dataIndex:'totaal',
            sorter: (a, b) =>  a.totaal < b.totaal ? -1 : 1,
        },
        {
          title:"Acties",
          dataIndex:'id',
          render: (value, record)=>{
          return(
            <div>
            
            <Button>
              <a href={`https://hetperhof.be/factuurponylessen/${value}`} target="_blank" rel="noopener noreferrer"><DownloadOutlined  /></a>
            </Button>
          {record.state !== 'paid' &&
            <Button onClick={()=>paidAfrekening({variables:{id:record.id}})}>
              <EuroCircleOutlined />
            </Button> }
           {record.state === 'Open' ? <Button onClick={()=>publishFactuur({variables:{id:record.id}})}>
              <CheckCircleOutlined />
            </Button>  :
            (!record.send & record.state !== 'paid') ? <Button onClick={()=>sendFactuur({variables:{id:record.id}})}>
              <MailOutlined />
            </Button>  : '' }           
           </div>
            )
          }
        }
        ]
    const rowData = !loading &&  data.allAfrekeningItems.edges.map(item=>
    ( {
          id:item.node.id,
          leerling:item.node.leerling,
          nummer : item.node.nummer,
          datum:item.node.datum,
          state:item.node.state,
          totaal: item.node.totaal,
          send:item.node.send,
      }
      )
      )
    const months = moment.monthsShort().map((item,i)=>(
      <Option value={i+1}>{item}</Option>))

    return(
      <AdminLayout
        title="Facturatie">
        <Form
          layout="inline"
          >
          <Form.Item>
            <Select
              style={{ width: 120 }} 
              value={month}
              allowClear ={ true}
              placeholder="Select Maand"  
              onChange={(value)=>setMonth(value)}
            >
            {months}
          </Select>
          </Form.Item>
          <Form.Item style={{ width: 120 }} >
            <Select 
                allowClear ={ true}
                onChange={(value)=> setYear(value)}
                value={year}
                placeholder="Selecteer jaar"
            style={{ width: 120 }} >
               <Option value={2023}>2023</Option>

               <Option value={2022}>2022</Option>
          
               <Option value={2021}>2021</Option>

              <Option value={2020}>2020</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <LeerlingSelect  
              allowClear
              onChange = {(value)=>setLeerling(value)}
              placeholder = {"Selecteer Leerling"}
              value={leerling ? leerling : undefined}
            style={{ width: 150 }}  />
          </Form.Item>
          <Form.Item>
            <Checkbox checked={nietBetaald} onChange={()=>setNietBetaald(!nietBetaald)}>Niet Betaald</Checkbox>
          </Form.Item>
        </Form>
        {error && "Oeps er ging iets fout"}
        <Button onClick={()=>createInvoices()}>Maak facturen van deze maand</Button>
        <IntlProvider value={enUSIntl}>
        {loading && <Spin />}

        <ProTable
        search={false}
        columns={columns}
        actionRef={actionRef}
        request={params => {return {data:rowData}}}
        rowKey="id"
        dateFormatter="string"
             summary={(pageData) => {
        let total = 0;
        
        pageData.forEach(({ totaal }) => {
          total += totaal;
        });
        return (
          <>
            <Table.Summary.Row>
                {total}
             </Table.Summary.Row>
          </>
        );
      }}
        toolBarRender={() => [
          <Button key="1" type="primary" onClick={()=>{setVisible(true)}}>
            <PlusOutlined />
            Voeg een afrekening toe
          </Button>,]}
      />
      <AfrekeningModal 
          visible={visible}
          onCancel= {()=>setVisible(false)}
          onOk={()=>setVisible(false)}
      />
      </IntlProvider>
    </AdminLayout>
        )        
        
        

}

export default withRouter(Profile)