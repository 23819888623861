import React, {useState, useRef} from 'react';
import {EditOutlined, PlusOutlined} from '@ant-design/icons'
import { withRouter} from 'react-router-dom'
import AdminLayout from './AdminLayout'
import {Spin, Button, Tag} from 'antd'
import ProTable, {  IntlProvider, enUSIntl} from '@ant-design/pro-table';
import AddPaardModal  from './AddPaardModal'
import AddEigenaarModal  from './AddEigenaarModal'
import AddFactuurItem from './AddFactuurItem'
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {Link } from 'react-router-dom'


export const GET_ALL_USERS = gql`
query($isDierenarts:Boolean){
  allUsers(isDierenarts:$isDierenarts){
    edges {
      node {
        id
        username
        firstName
        lastName
        email
        userprofile{
          id
          straat
          nummer
          postcode
          stad
          isDierenarts
          telefoonnummer
          dierenarts {
              id
              username
              firstName
              lastName
            }
          hoefsmid {
              id
              username
              firstName
              lastName
            }
        }
        paardSet {
          edges {
            node {
              id
              naam
              isGestald
            }
          }
        }
      }
    }
  }
}`



const Profile = (props)=>{
    const {data, error, loading}=useQuery(GET_ALL_USERS, {variables:{isDierenarts:props.isDierenarts}})
    const [editID, setEditID] = useState('')
    const [paardModalVisible, setPaardModalVisible]= useState(false)
    const [factuurModalVisible, setFactuurModalVisible]= useState(false)
    const [visible, setVisible]=useState(false)
    const actionRef = useRef();
    
    if(loading) return (<Spin />)
    const columns = [
       {
          title: 'Usernaam',
          dataIndex: 'username',
          render:(value, record)=>
          (<Link to={`/admin/user/${record.id}`}>{value}</Link>
            )
        },
        {
          title: 'Paarden',
          dataIndex: 'paardSet',
          render: (value, record)=>{
            const paardenTag = value.edges.map((paard)=>
            (
              <Tag>{paard.node.naam}</Tag>
              )
              
            )
            return(
              <div>
              {paardenTag}
              </div>
              )
          }
        },
        {
          title: 'Dierenarts',
          dataIndex: 'dierenarts',
          render: (value)=>{
            return value.username
          }
        },

        {
          title:"Acties",
          dataIndex:'id',
          render: (value, record)=>{
          return(
            <div>
            <Button onClick={()=>{
                    setEditID(value)
                    setVisible(true)
            }}><EditOutlined /></Button> 
            <Button onClick={()=>{
              setPaardModalVisible(true)
              setEditID(value)

            }}><PlusOutlined />Paard</Button> 
            <Button onClick={()=>{
              setFactuurModalVisible(true)
              setEditID(value)
            }}><PlusOutlined />Factuuritem</Button> 
            </div>
            )
          }
        }
        ]
    const rowData = data.allUsers.edges.map(item=>
    ( {
          id:item.node.id,
          username:item.node.username,
          dierenarts: item.node.userprofile.dierenarts,
          paardSet: item.node.paardSet,
          
      }
      )
      )
    return(
      <AdminLayout
        title="Eigenaars">
        {error && "An error occured"}
            <IntlProvider value={enUSIntl}>
          <ProTable
        search={false}
        columns={columns}
        actionRef={actionRef}
        request={params => {return {data:rowData}}}
        rowKey="id"
        dateFormatter="string"
        toolBarRender={() => [
          <Button key="3" type="primary" onClick={()=>{console.log("test");setVisible(true)}}>
            <PlusOutlined />
            Voeg een eigenaar toe
          </Button>,
        ]}
      />
      <AddEigenaarModal
        visible={visible}
        record = {visible && editID && data.allUsers.edges.filter((item)=>{return item.node.id === editID})[0]}
        onOk={()=>{

          setEditID('')
          setVisible('')
          if (actionRef.current) {
              actionRef.current.reload();
            }
        }}
        
        onCancel={()=>{
          setVisible('')
          setEditID('')
        }}/>
      <AddPaardModal 
        visible={paardModalVisible}
        eigenaar={editID}
        onOk={()=>{
          setPaardModalVisible(false)
            if (actionRef.current) {
              actionRef.current.reload();
            }
        }
        }
        onCancel={()=>{
          setPaardModalVisible(false)
        }}
      />
      <AddFactuurItem 
        visible={factuurModalVisible}
        eigenaar={factuurModalVisible && editID}
        onOk={()=>{
          setFactuurModalVisible(false)
          setEditID('')
          if (actionRef.current) {
              actionRef.current.reload();
          }
        }
        }
        onCancel={()=>{
          setFactuurModalVisible(false)
          setEditID('')   
        }}
      />
      </IntlProvider>
    </AdminLayout>
        )        
        
        

}

export default withRouter(Profile)