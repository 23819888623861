import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { useQuery} from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import { Row, Col, Button, Spin} from 'antd';
import {OverPack} from 'rc-scroll-anim';
import Pictures from './Pictures2'
import moment from 'moment'

const KAMPEN = gql`query{
  allKampItems(actief:true){
    edges{
      node{
        id
        startDate
        endDate
        aantalPlaatsen
        prijs
        volzet
        actief
      }
    }
  }
}`
const Content =(props)=> {

     const {data, loading}= useQuery(KAMPEN)

     if(loading) return (<Spin />)
     const kampList =data && data.allKampItems.edges.sort((a, b)=>{
       return new Date(a.node.startDate) - new Date(b.node.startDate)
     }).map(item=>{
       return (<li>Van {moment(item.node.startDate,'YYYY-MM-DD').format('DD MMM')} tot {moment(item.node.endDate,'YYYY-MM-DD').format('DD MMM')} (prijs: € {item.node.prijs}) {item.node.volzet && "--> Volzet"}</li>)
     })
    return (
      <div className='home-page-wrapper content5-wrapper' >
        <div className= 'home-page content5'>

          <div className="page2-title">
            Ponykamp
          </div> 
          <div>
            <div style={{marginBottom:'25px'}}>Tijdens de schoolvakanties organiseert Ponylessen 't Perhof VZW op de locatie van 't Perhof een ponykamp voor kindjes van 5 tot  8jaar (geboortejaren 2015 tot 2018). Hierbij leren de kinderen in kleine groepjes ponyrijden, pony’s verzorgen, spelen we spelletjes en doen we heel veel meer leuks!<br />
            Neem gerust een kijken in het fotoalbum.
            <br />

            
            <div style={{marginBottom:'25px', marginTop:'25px'}}>

              {data && data.allKampItems.edges.length ==0 ? 'Jammer, er staan momenteel geen kampen op het programma.' : 
              "Joepie er staan kampen op het programma. Bekijk de lijst hieronder. Inschrijven kan via de knop hieronder" }
              <ul style={{marginLeft:'75px', marginTop:'25px'}}>
                {kampList}
              </ul>
              
                
                
              
            </div>
              <div style={{marginBottom:'25px'}}>
              De kampen starten om 9u15 en eindigen om 12u30. <br/>
              Opvang is mogelijk van 8u30 tot 9u15 en van 12u30 tot 13u30 voor de prijs van 1€/uur. Op 1 en 2 november gaat het kamp gewoon door!

              </div>
              <Button type="primary"
              onClick={()=>window.open('https://docs.google.com/forms/d/e/1FAIpQLSeH8-n7obECYT8_lFLKuHZG3MkVk9hyS_zoCG-pivP11Zp5pw/viewform')}>Inschrijven</Button>
          
              <div style={{marginBottom:'25px'}}>

               De inschrijving is definitief na het betalen van het inschrijvingsgeld op rekening nummer BE67 7310 5128 5487 </div>
              
              <Pictures />

              
              <h3>Betaling en annulatie</h3>
              Inschrijving van het kamp is definitief na het betalen van een voorschot van €40 op de rekening van Ponylessen 't Perhof VZW (BE67 7310 5128 5487). Het inschrijvingsgeld moet volledig betaald zijn 14 dagen voor de aanvang van het kamp. Indien het inschrijvingsgeld niet tijdig betaald is heeft Ponylessen 't Perhof VZW het recht om de plek aan een andere deelnemer toe te wijzen. Annulatie is mogelijk tot 30 dagen voor het kamp. <br/>
                Annulatie voor medische redenen (doktersattest vereist) is mogelijk tot de dag voor de aanvang van het kamp. Het voorschot wordt niet terugbetaald en kan gebruikt worden bij een latere inschrijving of les.<br/>
              <h3>Verzekering</h3>
              Tijdens de lessen en kampen is er een verzekering voor lichamelijk ongevallen via Paardensport Vlaanderen. Kledij, brillen ed zijn niet verzekerd. Kostbare zaken (GSM, tablet, juwelen, geld, etc) worden best thuis gelaten.
              
            </div> 
        </div>
      </div>
    </div>
    
    );
  
}

export default Content;
