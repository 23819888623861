import React from 'react';
import { Layout, Menu, PageHeader } from 'antd';
import CustomLayout from '../../components/Layout'
import {Link} from 'react-router-dom'
const { Sider, Content } = Layout;



const AdminLayout =({children, ...props})=>{
    
    return(
        <CustomLayout>
        <Layout>
        <Sider style={{background: '#fff'}}>
        <Menu>
            <Menu.Item><Link to='/admin/paarden'>Paarden</Link></Menu.Item>
            <Menu.Item><Link to='/admin/eigenaars'>Eigenaars</Link></Menu.Item>
            <Menu.Item><Link to='/admin/dierenarts'>Dierenartsen</Link></Menu.Item>

            <Menu.Item><Link to='/admin/facturatie'>Facturen</Link></Menu.Item>
            <Menu.Item><Link to='/admin/factuuritem'>Factuur items</Link></Menu.Item>
            <Menu.Item><Link to='/admin/hooi'>Hooi</Link></Menu.Item>

        </Menu>
        </Sider>
            <Content>
            <PageHeader {...props}/>
                {children}
            </Content>
        </Layout>
        </CustomLayout>
        
        )
}

export default AdminLayout