import { gql } from 'apollo-boost'

const VALIDATE_TOKEN_QUERY = gql`
            mutation($token:String!){
                verifyToken(token:$token){
                    payload
                }
            }`
const GET_REFRESH_TOKEN = gql`
        mutation($token:String!){
            refreshToken(token:$token){
                token
                payload
            }
        }`
async function isLoggedIn (client){
    const token = localStorage.getItem('token')
    let bool=false
    if(token){
        //if we have a token check if the token is still valid.
            const p = client.mutate({mutation:VALIDATE_TOKEN_QUERY, variables:{token:token}, fetchPolicy:'no-cache'})
            await p.then(async ()=>{
                const q = client.mutate({mutation:GET_REFRESH_TOKEN, variables:{token:token}, fetchPolicy:'no-cache'})
                await q.then((results)=>{
                    localStorage.setItem('token', results.data.refreshToken.token)
                })
                bool=true
            }).catch((error)=>{
                localStorage.removeItem('token')
            })
    }
    return bool

}
export default isLoggedIn