import React, { useState } from 'react';
import {
  Checkbox,
  Col,
  Form,
  Input,
  Descriptions,
  Row,
  List,
  Button,
  Typography,
  Space,
} from 'antd';
import {card_content} from './ProductCards'
import {SEND_EMAIL}  from '../../landing/Content3'
import {  useMutation } from '@apollo/react-hooks';

const {Title} = Typography
const Bevestiging =({data, current, setCurrent, setData})=>{
      const [sendMail] = useMutation(SEND_EMAIL)

    const [voorwaarden, setVoorwaarden]=useState(false)
    const order = card_content.filter(item=>item.key===data.period)[0]
    console.log(data.extraInput.kilometers)
    console.log(order.kilometers)
    const kilometers = Math.max(0,(data.extraInput.kilometers - order.kilometers)) * 0.35
    let totaal = order.prijs + order.waarborg  + kilometers
    if(data.extraInput.hooi){
      totaal += 10
    }
    if(data.extraInput.cleaning){
      totaal += 50
    }

    return(
  <div>
      <Row gutter={[16,16]}>
        <Col span={24}>
        <List
            size='small'
            header={<Title level={2}>Overzicht bestelling</Title>}
            itemLayout="horizontal"
        >
        <List.Item>
          <List.Item.Meta
              title={<div>{order.title}</div>}
              description={order.description}
          />
          <div>€ {order.prijs}</div>
        </List.Item>
        
        {data.extraInput.hooi &&
        <List.Item>
          <List.Item.Meta
              title="Hooi"
              description="Hooi + net is beschikbaar in de truck"
          />
          <div>€ 10</div>
        </List.Item>}

        {data.extraInput.cleaning &&
        <List.Item>
          <List.Item.Meta
              title="Cleaning"
              description="Geen tijd om de truck proper terug te brengen? Laat de truck reinigen door ons."
          />
          <div>€ 50</div>
        </List.Item>}        


        <List.Item>
          <List.Item.Meta
              title="Kilometers"
              description="Er zijn een aantal kilometers in begrepen in de prijs. Extra kilometers kosten €0,35/km"
          />
          <div>€ {kilometers}</div>
        </List.Item>
        
        <List.Item>
          <List.Item.Meta
              title="Waarborg"
              description="De waarborg wordt na het terugbrengen en na aftrek van eventuele extras terugbetaald"
          />
          <div>€ {order.waarborg}</div>
        </List.Item>

        <List.Item>
          <List.Item.Meta
              title="Totaal"
              description="40% te betalen bij de bevestiging van de reservatie. De rest moet betaald worden bij afhaling via Payconic of cash"
          />
          <div>€ {totaal}</div>
        </List.Item>        
          </List>
          </Col>
      </Row>
      
      
      <Row  gutter={[16,16]}>
        <Col span={24}>
              <Descriptions title="Persoonsgegevens">
              <Descriptions.Item label="naam" span={3}>{data.gegevens.name}</Descriptions.Item>
              <Descriptions.Item label="email">{data.gegevens.email}</Descriptions.Item>
              <Descriptions.Item label="Telefoon" span={2}>{data.gegevens.phone}</Descriptions.Item>
              <Descriptions.Item label="Straat">{data.gegevens.straat} </Descriptions.Item>
              <Descriptions.Item label="gemeente" span={2}>{data.gegevens.gemeente} </Descriptions.Item>
              <Descriptions.Item label="rijbewijs">{data.gegevens.rijbewijs} </Descriptions.Item>


              <Descriptions.Item label="Extra info" span={3}>{data.gegevens.extra} </Descriptions.Item>

            </Descriptions>    
          
      
        </Col>
      </Row>
      <Row  gutter={[16,16]}>
        <Checkbox
            checked={voorwaarden}
            onChange={(e)=>setVoorwaarden(e.target.checked)}>
          Ik ben akkoord met de algemene <a href='https://drive.google.com/file/d/1EmoLow8KZvCjsLrTlYqfoQX4FaBCJEtW/view?usp=sharing' target='_blank'>hier</a>
        </Checkbox>
      </Row>
      <Row  gutter={[16,16]}>
      Uw bestelling is pas definitief na de ontvangst van een bevestigingsemail van 't Perhof en na het betalen van 40% voorshot op rekening nummer BE48 7360 6848 8027.
      </Row>
      <Row  gutter={[16,16]} justify="center">
        <Button type="primary" disabled={!voorwaarden}
          onClick={()=>{
            
            sendMail({variables:{
              email:data.gegevens.email,
              naam:data.gegevens.name,
              topic:"Verhuur Truck",
              question:data,
            }})
            setCurrent(current+1)

          }}
        >Bevestig reservering</Button>
      </Row>
      </div>
        )
}

export default Bevestiging
