import gql from 'graphql-tag';
import {UserFragment } from './UserFragment'
export const PaardFragment =  gql`

fragment PaardFragment on PaardType {
    id
    isGestald
    startDate
    endDate
    eigenaar {
        ...UserFragment
    }
}${UserFragment}`

