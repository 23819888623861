import React from 'react';

import {Spin} from 'antd'
import { useQuery} from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import Layout from '../components/Layout'


const GET_ALL =gql`
query{ 
  allAgendaItems {
    edges {
      node {
        id
      }
    }
  }

}`
const Profile = ({props})=>{
  const { loading, error, data } = useQuery(GET_ALL);

  if (loading) return <Spin />;
  const items = data && data.allAgendaItems.edges.map(item=>{
    return <li key={item.node.id}>{item.node.id}</li>
  })
    return(
      <Layout>
      {error && error.message}
        <div>
        </div>
        <ul>
        {items}
        </ul>
    </Layout>
        )        
        
        

}

export default Profile