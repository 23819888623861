import React, {useState, useRef} from 'react';
import {EditOutlined,DeleteOutlined, ExclamationCircleOutlined, DownloadOutlined} from '@ant-design/icons'
import { withRouter} from 'react-router-dom'
import AdminLayout from './AdminLayout'
import {Spin, Button, message, Modal} from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import ProTable, {  IntlProvider, enUSIntl} from '@ant-design/pro-table';
import AddPaardModal  from './AddPaardModal'
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
const { confirm } = Modal;

var moment = require('moment'); 

export const GET_ALL_PAARDEN = gql`
query{
  allPaarden{
    edges{
      node{
        id
        naam
        huur
        isGestald
        eigenaar{
          id
          username
        }
        startDate
        endDate
      }
    }
  }
}`

const DELETE_PAARD = gql`
mutation($id:String!){
    deletePaard(id:$id){
        deleted
    }
}
`

const Profile = (props)=>{
    const {data, error, loading}=useQuery(GET_ALL_PAARDEN)
    const [editID, setEditID] = useState('')
    const [visible, setVisible]=useState(false)
    const [deletePaard]=useMutation(DELETE_PAARD,{
    onCompleted(...params){ 
      if (actionRef.current) {
        actionRef.current.reload();
      }
        message.success("Paard succesvol verwijdered")
        },
      awaitRefetchQueries:true,
      refetchQueries:[{query:GET_ALL_PAARDEN}],
    })
    const actionRef = useRef();

    if(loading) return (<Spin />)
    const columns = [
       {
          title: 'Paard Naam',
          dataIndex: 'naam',

            
        },
        {
          title: 'Eigenaar',
          dataIndex: 'eigenaar',
          render: (value, record)=>{
            return(
              <div>
              {value.username}
              </div>
              )
          }
        },
        {
          title: 'Start maand',
          dataIndex: 'startDate',
          
          render: (value)=>{
            return value ? moment(value,'YYYY-MM-DD').format("MMM YYYY") : '-'
          }
        },
        {
          title: 'Gestald',
          dataIndex: 'isGestald',
          render: (value)=>{
            return (value ? 'Ja' : 'Nee')
          },
          sorter: (a,b)=> a.isGestald - b.isGestald,
          defaultSortOrder: 'descend',

        },
        {
          title: 'Laatste maand',
          dataIndex: 'endDate',
          render: (value)=>{
            
            return value!=='-' ? moment(value,'YYYY-MM-DD').format("MMM YYYY") : '-'
          }
        },
        {
          title:"Acties",
          dataIndex:'id',
          render: (value, record)=>{
          return(
            <div>
            <Button onClick={()=>{
                    setEditID(value)
                    setVisible(true)
            }}><EditOutlined /></Button> 
             <Button>
              <a href={`https://hetperhof.be/paard/${value}`} target="_blank" rel="noopener noreferrer"><DownloadOutlined  /></a>
            </Button>
            <Button onClick={()=>  confirm({
                  title: 'Wil je dit paard zeker verwijderen?',
                  icon: <ExclamationCircleOutlined />,
                  content: 'Paard zal verwijderd worden',
                  okText: 'Yes',
                  okType: 'danger',
                  cancelText: 'No',
                  onOk() {
                      deletePaard({variables:{id:value}})
                    },
                  onCancel() {
                    console.log('Cancel');
                  },
                })
            }>
            <DeleteOutlined /></Button>
            
            </div>
            )
          }
        }
        ]
    const rowData = data.allPaarden.edges.map(item=>
    ( {
          id:item.node.id,
          naam:item.node.naam,
          eigenaar:item.node.eigenaar,
          startDate:item.node.startDate,
          isGestald:item.node.isGestald,
          endDate:item.node.endDate,
          
      }
      )
      )
    return(
      <AdminLayout
        title="Paarden">
        {error && "An error occured"}
            <IntlProvider value={enUSIntl}>
          <ProTable
        search={false}
        columns={columns}
        actionRef={actionRef}
        request={params => {return {data:rowData}}}
        rowKey="id"
        dateFormatter="string"
        headerTitle="Paarden"
        toolBarRender={() => [
          <Button key="3" type="primary" onClick={()=>{console.log("test");setVisible(true)}}>
            <PlusOutlined />
            Voeg een Paard toe
          </Button>,
        ]}
      />
      <AddPaardModal 
        visible={visible}
        record = {editID && data.allPaarden.edges.filter((item)=>(item.node.id===editID))[0]}
        onOk={()=>{
            setVisible(false)
            if (actionRef.current) {
              actionRef.current.reload();
            }
            setEditID('')
            
        }
        }
        onCancel={()=>{
          setVisible(false)
          setEditID('')

        }}
      />
      </IntlProvider>
    </AdminLayout>
        )        
        
        

}

export default withRouter(Profile)