import React from 'react';

import { useQuery} from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Spin, Select } from 'antd';
const { Option } = Select;

export const GET_ALL_LEERLINGEN = gql`
query{
  allLeerlingItems {
    edges {
      node {
        id
        leerling{
          id
          firstName
          lastName
        }
      }
    }
  }
}`

const UserSelect = (props)=>{
    const {data, loading}= useQuery(GET_ALL_LEERLINGEN)
    if(loading) return (<Spin />)
    const options = data.allLeerlingItems.edges.map(user =>(
        <Option key={user.node.id} value={user.node.id}>{user.node.leerling.firstName} {user.node.leerling.lastName}</Option>
        )
            
    )
    return(
        <Select 
            {...props}
            showSearch
            optionFilterProp="children"
            allowClear= {true}

            value={props.value}
            onChange={(value)=>{props.onChange(value)}}>
            {options}
        </Select>
        )
}

export default UserSelect