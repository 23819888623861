import React, {useReducer, useState}from 'react';
import AdminLayout from './AdminLayout';
import { Statistic, Row, Col, Button, Spin, Card, Table } from 'antd';
import HooiTimeline from './components/HooiTimeline'
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import AddHooiModal from './AddHooiModal'

var moment = require('moment'); 

const HOOI_QUERY = gql`
query{
  hooiStock
  voordroogStock
  allHooiList(orderBy:"-datum"){
    edges{
      node{
        id
        soortHooi
        datum
        kudde
        aantal
      }
    }
  }
  allHooiPerMaand{
    month
    kudde
    soortHooi
    aantal_Sum
  }
}`
const Hooi = ()=>{
    const [visible, setVisible]= useState(false)
    const [isVerbruik, setIsVerbruik]= useState(false)

    const {data, error, loading}=useQuery(HOOI_QUERY)
    const sumReducer = (accumulator, curr) => accumulator + Math.abs(curr.aantal_Sum);
    const startOfMonth = moment().startOf('month')
    console.log(data && data.allHooiPerMaand.filter(item=> (item.aantal_Sum < 0 && moment(item.month).isSame(startOfMonth) && item.soortHooi=== 'Voordroog Hooi')))
    let voordroog_huidige_maand = data && data.allHooiPerMaand.filter(item=> (item.aantal_Sum < 0 && moment(item.month).isSame(startOfMonth) && item.soortHooi=== 'Voordroog Hooi')).reduce(sumReducer, 0)
    let hooi_huidige_maand = data && data.allHooiPerMaand.filter(item=> (item.aantal_Sum < 0 && moment(item.month).isSame(startOfMonth) && item.soortHooi=== 'Hooi')).reduce(sumReducer, 0)
    let startOfLastMonth = moment().subtract(1, 'month').startOf('month')
    let voordroog_vorige_maand = data && data.allHooiPerMaand.filter(item=> (item.aantal_Sum < 0 && moment(item.month).isSame(startOfLastMonth) && item.soortHooi=== 'Voordroog Hooi')).reduce(sumReducer, 0)
    let hooi_vorige_maand = data && data.allHooiPerMaand.filter(item=> (item.aantal_Sum < 0 && moment(item.month).isSame(startOfLastMonth) && item.soortHooi=== 'Hooi')).reduce(sumReducer, 0)
    const averageHooiData = []
    data && data.allHooiPerMaand.forEach(item=>{
      //check if the column already exist
      let filterList = averageHooiData.filter(resultItem=>(moment(resultItem.month).isSame (moment(item.month)) && resultItem.kudde === item.kudde))
      if(filterList.length > 0){
        //als de lenght 
        console.log('exist')
        filterList[0].sum +=item.aantal_Sum
      }else{
        console.log('new')
        averageHooiData.push ({month:item.month, kudde:item.kudde, sum:item.aantal_Sum})
      }
        
    })
    console.log(averageHooiData)
    const columns = [  {
    title: 'Maand',
    dataIndex: 'month',
    render:(value)=>moment(value).format('MMMM')
      },
    {
      title:'Kudde',
      dataIndex: 'kudde',
    },
    {
      title:'Aantal pakken',
      dataIndex:'sum',
      render:(value)=>Math.abs(value)
    }]
     
    if (loading) return (<Spin />)
    return(
        <AdminLayout title="Hooi" extra = {[ <Button onClick={()=>{setVisible(true); setIsVerbruik(true)}} type='primary'>Verbruik</Button>, 
                                              <Button onClick={()=>{setVisible(true); setIsVerbruik(false)} }>Levering</Button>]}>
          <Row gutter={32}>
            <Col span={4}>
            <Card>
              <Statistic title="Hooi Stock" value={data.hooiStock} />
            </Card>
            </Col>

            <Col span={4}>
            <Card>
              <Statistic title="Voordroog Stock " value={data.voordroogStock} />
            </Card>
            </Col>
            
            <Col span={4}>
            <Card>
              <Statistic title="Hooi Huidige maand" value={hooi_huidige_maand} />
            </Card>
            </Col>
            <Col span={4}>
            <Card>
              <Statistic title="Hooi vorige maand" value={hooi_vorige_maand} />
            </Card>
            </Col>

            <Col span={4}>
            <Card>
              <Statistic title="VD huidige maand" value={voordroog_huidige_maand } />

            </Card>
            </Col>
            <Col span={4}>
            <Card>
              <Statistic title="VD vorige maand" value={voordroog_vorige_maand} />
            </Card>
            </Col>
          </Row> 
          
          
          <Row gutter={[16,16]} style={{margin:'12px'}}>
          <Col span={12} >
          <Card title="Gemiddelde">
            <Table columns={columns} dataSource={data && averageHooiData} />
          </Card>
          </Col>
          
          <Col span={11} >
          <Card title="tijdslijn">
            <HooiTimeline data = {data.allHooiList}/>
          </Card>
          </Col>
          </Row>
        <AddHooiModal 
          visible={visible}
          onOk={()=>setVisible(false)}
          onCancel={()=>setVisible(false)}
          isVerbruik = {isVerbruik}
          refetchQueries = { [{query:HOOI_QUERY}]}
        />
  </AdminLayout>

        )
}

export default Hooi