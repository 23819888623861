import React from 'react';
import {Modal, Form, Input, message, InputNumber, DatePicker} from 'antd';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

import LeerlingSelect from '../components/LeerlingSelect'
import LesgeverSelect from '../components/LesgeverSelect'
import PaardSelect from '../components/PaardSelect'
import AccomodatieSelect from '../components/AccomodatieSelect'
import {GET_ALL_KALENDERITEMS} from './query'
import RepeatPanel from './RepeatPanel'
import AfrekeningSelect from '../components/AfrekeningSelect'
var moment = require('moment'); 
const { RangePicker } = DatePicker;


const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const disabledMinutes =()=>{
    const needed =  [0, 15, 30, 45]
    let result = []
    var i
    for ( i=0; i< 60; i++){
        if(!needed.includes(i)){
            result.push(i)
        }
    }
    return result
}
const EventModal =({visible, onOk, onCancel, event, showRepeatPanel, mutation, slot})=>{
    const [form] = Form.useForm();
    const [addKalenderItem, {error}]= useMutation(mutation,{
    onCompleted(...params){ 
        message.success("les is toegevoegd")
        },
      awaitRefetchQueries:true,
      refetchQueries:['allKalenderItems'],
    })
    const disable = event && showRepeatPanel
    const onFinish =  async (values)=>{
        values.startDate =  values.date && values.date[0].format()    
        values.endDate =  values.date && values.date[1].format()  
        console.log(values.lastDate)
        if(event) {values.id = event.node.id}
        if(event && showRepeatPanel){ 
            values.lastDate =  values.lastDate && values.lastDate.format()  
            values.after = values.date && values.date[0].format()  
            if(!values.lastDate){
                delete values.lastDate
            }
            if(!values.accomodatie){
                delete values.accomodatie
            }
            //in case we have a repeat panal, we want to have the ID of the event and not the eventitem
            values.id = event.node.event.id
        }
        delete values.date
        
        await addKalenderItem({variables:{event:values}})
        onOk()
        form.resetFields();

    }
    let defaultValues = {
        date: slot ?[moment(slot[0], 'YYYY-MM-DD hh:mm:ss'), moment(slot[1], 'YYYY-MM-DD hh:mm:ss')]: undefined, 
        prijs:35,
        
    }
    if(event)  {
        defaultValues ={
            date :[moment(event.node.startDate), moment(event.node.endDate)],
            leerling  : event.node.leerling && event.node.leerling.id,
            lesgever:event.node.lesgever && event.node.lesgever.id,
            accomodatie:event.node.accomodatie && event.node.accomodatie.id,
            paard: event.node.paard && event.node.paard.id,
            prijs:event.node.prijs,
            afrekening:event.node.afrekening && event.node.afrekening.id
        }
    if(event && showRepeatPanel)
    defaultValues= {
        repeat:event.node.event.repeat,
        lastDate:event.node.event.lastDate && moment(event.node.event.lastDate) ,
        aantal: event.node.event.aantal,
        
    }
    }
    form.setFieldsValue(defaultValues)
    return(
        <Modal 
            title="Voeg een nieuw event toe"
            visible={visible}
            onCancel={()=>{
                form.resetFields()
                onCancel()}}
            onOk={()=>{
            form.submit()
            }}
            >
            {error && error.message}
        <Form 
            form={form}
            {...layout} 
            onFinish={onFinish}>
          <Form.Item
            label="date"
            name="date"

            rules={[{ required: true, message: 'Selecteer een datum' }]}
        >
            
            <RangePicker 
                disabledMinutes = {disabledMinutes}
                hideDisabledOptions = {true}
                format="DD-MM-YYYY hh:mm"
                disabled={[disable, disable]}

                showTime />
        </Form.Item>
        {showRepeatPanel && <RepeatPanel disable={disable}/>}
         <Form.Item
            label="Leerling"
            name="leerling"
            rules={[{ required: true, message: 'Selecteer een deelnemer' }]}
        >
                    <LeerlingSelect 
                        onChange={(value)=>{console.log(value); form.setFieldsValue({leerling:value})}}
                        value={form.getFieldValue('leerling')}
                        />         
        </Form.Item>
        <Form.Item
            label="Lesgever"
            name="lesgever"
            rules={[{ required: true, message: 'Selecteer een lesgever' }]}
        >
                    <LesgeverSelect 
                        onChange={(value)=>form.setFieldsValue({lesgever:value})}
                        value={form.getFieldValue('lesgever')}
                        />         
        </Form.Item>
        <Form.Item
            label="Paard"
            name="paard"
        >
                    <PaardSelect 
                        onChange={(value)=>form.setFieldsValue({paard:value})}
                        value={form.getFieldValue('paard')}
                        />         
        </Form.Item>
        <Form.Item
            label="accomodatie"
            name="accomodatie"
        >
                    <AccomodatieSelect 
                        onChange={(value)=>form.setFieldsValue({accomodatie:value})}
                        value={form.getFieldValue('accomodatie')}
                        />         
                    </Form.Item>
        <Form.Item
            label="Prijs"
            name="prijs"
        >
                <InputNumber />       
        </Form.Item>
        {event && 
        <Form.Item
            label="Afrekening"
            name="afrekening"
        >
                    <AfrekeningSelect 
                        onChange={(value)=>form.setFieldsValue({afrekening:value})}
                        value={form.getFieldValue('afrekening')}
                        leerling = {form.getFieldValue('leerling')}
                        />          
                    </Form.Item>}
        </Form>
        </Modal>
        )
}

export default EventModal