import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Modal, Input, message, Form, DatePicker, InputNumber, Switch } from 'antd';
import {GET_ALL_LEERLINGEN} from './Leerling'
import SeizoenSelect from "../components/SeizoenSelect"
import LeerlingSelect from "../components/LeerlingSelect"

var moment = require('moment'); 
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const ADD_SUBSCRIPTION = gql`
mutation($input:CreateSubscriptionInput!){
  createSubscription(input:$input){
    subscription
    {
      id
    }
  }
}`

const LeerlingModal=({visible, onOk, onCancel, leerling})=>{
    const [form] = Form.useForm();
    const [addLeerling]= useMutation(ADD_SUBSCRIPTION,{
    onCompleted(...params){ 
        message.success("subscription succesvol toegevoegd")
        },
      awaitRefetchQueries:true,
      refetchQueries:[{query:GET_ALL_LEERLINGEN}],
    })

    const onFinish = async (values)=>{
        await addLeerling({variables:{input:values}})
        onOk()
        form.resetFields();

    }
    let defaultValues = {}
    if(leerling){
        defaultValues ={
          leerling:leerling,
     }
        form.setFieldsValue(defaultValues)
    }
    
    return(
        <Modal
            title="Voeg een inschrijving toe"
            visible={visible}
            onCancel={()=>{
                form.resetFields()
                onCancel()
                
            }}
            onOk={()=>{
                form.submit()
            }}
            >
            <Form 
                form={form}
                {...layout} 
                onFinish={onFinish}>
                <Form.Item
                    label="leerling"
                    name="leerling"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                
                >
                    <LeerlingSelect
                        onChange={(value)=>{form.setFieldsValue({leerling:value})}}
                        value={form.getFieldValue('leerling')}
                    />
                </Form.Item>
                
                <Form.Item
                    label="seizoen"
                    name="seizoen"
                    rules={[{ required: true, message: 'Geef de eigenaar van het paard!' }]}
                
                >
                        <SeizoenSelect
                                                
                                    onChange={(value)=>{form.setFieldsValue({seizoen:value})}}
                                    value={form.getFieldValue('seizoen')}
                        />
                </Form.Item>
                <Form.Item
                    label="lidgeld"
                    name="lidgeld"
                    
                >
                    <InputNumber />
                </Form.Item>
             </Form>       
        </Modal>
        )
}

export default LeerlingModal