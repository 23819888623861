import ScrollAnim from 'rc-scroll-anim';
import React from 'react';
import {MenuOutlined} from '@ant-design/icons'
import TweenOne from 'rc-tween-one';
import Banner from './Banner'
import Content0 from './Content0'
import './less/antMotionStyle.less';
import Content3 from './Content3'
import Content5 from './Content5'
import Footer1 from './Footer1'
import ContentLessen from './ContentLessen'
import ContentKamp from './kampjes'
import logo from './img/logo-transparent.png'
import Truck from './truck'
import {Link as DomLink} from "react-router-dom"
const Link = ScrollAnim.Link;
const Element = ScrollAnim.Element;
const ScrollOverPack = ScrollAnim.OverPack;
const EventListener = ScrollAnim.Event;
class Demo extends React.Component {
  constructor(props){
    super(props)
    this.state= {responsive : ''}

  }
  componentDidMount() {
    // 添加改变窗口事件,可加setTimeout
    EventListener.addEventListener('resize.userResize', this.barAnimate.bind(this));
    ScrollAnim.scrollScreen.init({ loop: true, location: ['Home', 'Accomodatie', 'Location', 'Contact'] });
  }
  onFocus = (e) => {
    this.dom = e.target;
    this.barAnimate();
  }

  barAnimate = () => {
    if (!this.dom) {
      return;
    }
    const bar = this.bar;
    bar.style.left = `${this.dom.getBoundingClientRect().left}px`;
  }
  render() {
    return (<div  className="templates-wrapper"
>
      <div className={`nav ${this.state.responsive}`}>
        <TweenOne
            animation={{ x: -30, type: 'from', ease: 'easeOutQuad' }}
          >
          <div className={`nav-title ${this.state.responsive}`}>
          <img src={logo} alt="Banner" style={{height:'50px', marginRight:"7px"}}/>'t Perhof
          </div>
          </TweenOne>
        <div className='nav-wap'>
          <Link
            className={`nav-list ${this.state.responsive}`}
            to="Home"
            showHeightActive="300"
            onFocus={this.onFocus}
            onBlur={()=>this.setState({...this.state, responsive:''})}
          >
            Home
          </Link>
          <Link
            className={`nav-list ${this.state.responsive}`}
            to="lessen"
            showHeightActive="300"
            onFocus={this.onFocus}
            onBlur={()=>this.setState({...this.state, responsive:''})}
          >
            Ponylessen
          </Link>
          <Link
            className={`nav-list ${this.state.responsive}`}
            to="kampen"
            showHeightActive="300"
            onFocus={this.onFocus}
            onBlur={()=>this.setState({...this.state, responsive:''})}
          >
            Ponykamp
          </Link>
          <Link
            className={`nav-list ${this.state.responsive}`}
            to="Accomodatie"
            showHeightActive="300"
            onFocus={this.onFocus}
            onBlur={()=>this.setState({...this.state, responsive:''})}
          >
            Stalling
          </Link>
           <Link
            className={`nav-list ${this.state.responsive}`}
            to="Location"
            showHeightActive="300"
            onFocus={this.onFocus}
            onBlur={()=>this.setState({...this.state, responsive:''})}
          >
            Locatie
          </Link>
          <Link
            className={`nav-list ${this.state.responsive}`}
            to="Contact"
            showHeightActive="300"
            onFocus={this.onFocus}
            onBlur={()=>this.setState({...this.state, responsive:''})}
          >
            Contact
          </Link>
          <div className="nav-icon">
              <MenuOutlined onClick={()=>{
              const resp = this.state.responsive ==='' ? 'responsive' : ''
              this.setState({...this.state, responsive:resp})
            }} />
        </div>
          <div ref={(c) => { this.bar = c; }} className={`nav-bar ${this.state.responsive}`} />
        </div>

      </div>
      
      <div>
       <Element id="Home" style={{height:'100vh'}}>
            <Banner/>
        </Element>
      </div>
      <div id="lessen" style={{ overflow: 'hidden', minHeight:'100vh', backgroundColor:"#FFF7E8"}}>
        <ContentLessen />
      </div>
      <div id="kampen" style={{ overflow: 'hidden', minHeight:'100vh'}}>
        <ContentKamp />
      </div>

      <div id="Accomodatie" style={{ overflow: 'hidden', minHeight:'100vh', backgroundColor:"#FFF7E8"}}>
        <Content0 />
      </div>
    {/*  <div id="truck" style={{ overflow: 'hidden', minHeight:'100vh', backgroundColor:"#FFF7E8"}}>
        <Truck />
      </div>*/}
      <ScrollOverPack
        id="Location"
        playScale={1}
      >
        <Content5 />
      </ScrollOverPack>
      
      <ScrollOverPack
        id="Contact"
        playScale={1}
        style={{backgroundColor:"#FFF7E8"}}
      >
        <Content3 />
      </ScrollOverPack>
      <Footer1 />
    </div>);
  }
}
export default Demo