import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';

import './index.css';
import { ApolloProvider } from '@apollo/react-hooks';
import * as serviceWorker from './serviceWorker';
import App from './App'
import ApolloClient from 'apollo-boost'
//import { ApolloProvider } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import isLoggedIn from './resolvers/isLoggedin'

const client = new ApolloClient({
  uri: 'https://www.hetperhof.be/gql/',
  request: (operation) => {
    const token = localStorage.getItem('token')
    operation.setContext({
      headers: {
        authorization: token ? `JWT ${token}` : ''
      }
    })
  },
  cache: new InMemoryCache(),
    resolvers:{
    Query:{isLoggedIn:(_root, variables, { client }) =>isLoggedIn(client)
    
  }},
});


ReactDOM.render(
  <React.StrictMode>
  <ApolloProvider client={client}>
      <App />
  </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
