import React from 'react';

import { useQuery} from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Select, Spin } from 'antd';

const { Option } = Select;
const ALL_SEIZOEN=gql`
query{
  allSeizoenItems{
    edges{
      node{
        id
        start
        end
        current
      }
    }
  }
}`

const UserSelect = (props)=>{
    const {data, loading}= useQuery(ALL_SEIZOEN)
    if(loading) return (<Spin />)
    const options = data.allSeizoenItems.edges.map(seizoen =>(
        <Option key={seizoen.node.id} value={seizoen.node.id}>{seizoen.node.start + " tot " + seizoen.node.end  +( seizoen.node.current ? " (current)" : "")  }</Option>
        )
            
    )
    return(
        <Select 
            {...props}
            value={props.value}
            onChange={(value)=>props.onChange(value)}>
            {options}
        </Select>
        )
}

export default UserSelect