import React from 'react'
import './banner.less'
import banner from './img/banner.jpg'
import stallen from './img/stallen.jpg'
import binnenpiste from './img/binnenpiste.GIF'
import longeercirkel from './img/longeercirkel.GIF'
import solarium from './img/wasplaats.GIF'
import wasplaats from './img/wasplaats2.GIF'
import weide from './img/weide.GIF'
import piste from './img/piste.GIF'
import piste2 from './img/piste2.GIF'
import stallen2 from './img/stallen.GIF'
import stallen3 from './img/stallen3.GIF'
import weide2 from './img/weide2.GIF'

import BannerAnim, { Element } from 'rc-banner-anim';
import 'rc-banner-anim/assets/index.css';
const BgElement = Element.BgElement;
class Demo extends React.Component {
  render(){
    const images = [banner, stallen, binnenpiste, longeercirkel, solarium, wasplaats, weide, piste, piste2, stallen2, stallen3, weide2]
    const elements = images.map((item,i)=>{
      
      return (
        <Element 
          prefixCls="banner-user-elem"
          key={i}
        >
          <BgElement
            key="bg"
            className="bg"
            style={{
              backgroundImage: `url(${item})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          
        </Element>)
      
    })
    return (
      <BannerAnim autoPlay prefixCls="banner-user" style={{height:'100vh'}}>
        {elements}
      </BannerAnim>);
  }
}
export default Demo