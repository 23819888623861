import React from 'react';
import {  useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Modal,  Input, message, Form, Switch} from 'antd';
import AllUserSelect from './components/AllUsersSelect';
import {GET_ALL_USERS} from './Users'
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ADD_USER= gql`
mutation ($id: ID,$firstName:String, $lastName:String, $email:String, $dierenarts: ID, $isDierenarts: Boolean, $nummer: String, $postcode: Int, $stad: String, $straat: String, $telefoonnummer: String, $username: String!, $hoefsmid:ID) {
  user(id: $id, dierenarts: $dierenarts, isDierenarts: $isDierenarts, nummer: $nummer, postcode: $postcode, stad: $stad, straat: $straat, telefoonnummer: $telefoonnummer, username: $username, firstName:$firstName, lastName:$lastName, hoefsmid:$hoefsmid,email:$email) {
    user {
      id
      user {
        id
        firstName
        lastName
        email
      }
      stad
      straat
      isDierenarts
      dierenarts {
        id
      }
      hoefsmid{
        id
      }
    }
  }
}`
const AddUserModal=({visible, onOk, onCancel, record})=>{
    const [form] = Form.useForm();
    const [addUser, {error: mutationError}]= useMutation(ADD_USER,{
    onCompleted(...params){ 
        message.success("User succesvol toegevoegd")
        onOk()
        },
      awaitRefetchQueries:true,
      refetchQueries:[{query:GET_ALL_USERS, variables:{isDierenarts:false}}],
    onError(...params){
        console.log("error")
    }
    })

    const onFinish = async (values)=>{
        if(record) {values.id = record.node.id}
        await addUser({variables:values})
        
        form.resetFields();

    }
    let defaultValues = {}
    if(record){
        defaultValues ={
            username:record.node.username,
            firstName:record.node.firstName,
            lastName:record.node.lastName,
            email:record.node.email,
            telefoonnummer:record.node.userprofile.telefoonnummer,
            hoefsmid:record.node.userprofile.hoefsmid && record.node.userprofile.hoefsmid.id,
            straat:record.node.userprofile && record.node.userprofile.straat,
            nummer:record.node.userprofile && record.node.userprofile.nummer,
            postcode:record.node.userprofile && record.node.userprofile.postcode,
            stad:record.node.userprofile && record.node.userprofile.stad,
            dierenarts: record.node.userprofile.dierenarts && record.node.userprofile.dierenarts.id,
            isDierenarts:record.node.userprofile && record.node.userprofile.isDierenarts,
        }
        form.setFieldsValue(defaultValues)
    }

    return(
        <Modal
            title="Voeg een Eigenaar toe"
            visible={visible}
            onCancel={()=>{
                onCancel()
                form.resetFields()
            }
            }
            defaultValues={defaultValues}
            onOk={()=>{
                form.submit()
            }}
            >
            {mutationError && mutationError.message}

            <Form 
                form={form}
                {...layout} 
                onFinish={onFinish}>
                 <Form.Item
                    label="Gebruikersnaam"
                    name="username"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                
                >
                        <Input />
                </Form.Item>
                <Form.Item
                    label="Voornaam"
                    name="firstName"
                    rules={[{ required: true, message: 'Please input your username!' }]}

                >
                    <Input />

                </Form.Item>
                <Form.Item
                    label="Achternaam"
                    name="lastName"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                        <Input />

                </Form.Item>
                <Form.Item
                    label="Straat"
                    name="straat"
                    rules={[{ required: true, message: 'Please input your username!' }]}

                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Nummer"
                    name="nummer"

                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Postcode"
                    name="postcode"

                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Stad"
                    name="stad"
    
                >
                <Input />
                </Form.Item>
                <Form.Item
                    label="email"
                    name="email"
                >
                
                <Input />
                </Form.Item>
                <Form.Item
                    label="telefoonnummer"
                    name="telefoonnummer"
                >
                
                <Input />
                </Form.Item>
                <Form.Item
                    label="Dierenarts"
                    name="dierenarts"
                >
                    <AllUserSelect 
                        isDierenarts={true}
                        onChange={(value)=>form.setFieldsValue({dierenarts:value})}
                        value={form.getFieldValue('dierenarts')}
                        />                
                </Form.Item>
                <Form.Item
                    label="Hoefsmid"
                    name="hoefsmid"
                >
                    <AllUserSelect 
                        isDierenarts={true}
                        onChange={(value)=>form.setFieldsValue({hoefsmid:value})}
                        value={form.getFieldValue('hoefsmid')}
                        />                
                </Form.Item>
                <Form.Item
                    label="Is een dierenarts"
                    name="isDierenarts"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
          </Form>       
        </Modal>
        )
}

export default AddUserModal