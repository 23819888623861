import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Modal, Input, message, Form, DatePicker, InputNumber, Select} from 'antd';
import AllUserSelect from './components/AllUsersSelect';
import {GET_ALL_PAARDEN} from './Paarden'
import {GET_ALL_USERS} from './Users'

const Option = Select

var moment = require('moment'); 
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ADD_HOOI= gql`
mutation ($input: HooiMutationInput!) {
  addHooi(input: $input) {
    clientMutationId
    errors{
      field
      messages
    }
  }
}`

const AddPaardModal=({visible, onOk, onCancel, isVerbruik, refetchQueries})=>{
    const [form] = Form.useForm();
    const [addFactuur]= useMutation(ADD_HOOI,{
    onCompleted(...params){ 
        message.success("Hooi succesvol toegevoegd")
        },
      awaitRefetchQueries:true,
      refetchQueries:refetchQueries,
    })

    const onFinish = async (values)=>{
        values.datum =  values.datum && values.datum.format('YYYY-MM-DD')    
        if(isVerbruik) {values.aantal= -1}
        await addFactuur({variables:{input:values}})
        onOk()
        form.resetFields();

    }
    return(
        <Modal
            title="Geleverd hooi"
            visible={visible}
            onCancel={()=>{
                form.resetFields()
                onCancel()
                
            }}
            onOk={()=>{
                form.submit()
            }}
            >
            <Form 
                form={form}
                {...layout} 
                onFinish={onFinish}>
                <Form.Item
                    label="Datum"
                    name="datum"
                    rules={[{ required: true, message: 'Please input a date' }]}
                
                >
                    <DatePicker />
                </Form.Item>
                {!isVerbruik && <Form.Item
                    label="Aantal"
                    name="aantal"
                    rules={[{ required: true, message: 'Geef het aantal pakken' }]}
                >
                   <InputNumber />
                </Form.Item>  }                
                <Form.Item
                    label="Soort Hooi"
                    name="soortHooi"

                >
                    <Select>
                        <Option value="Voordroog Hooi">Voordroog</Option>
                        <Option value="Hooi">Hooi</Option>
                    </Select>
                </Form.Item>
               {isVerbruik && <Form.Item
                    label="Kudde"
                    name="kudde"
                >
                    <Select>
                        <Option value="Ponies">Ponies</Option>
                        <Option value="Merries">Merries</Option>
                        <Option value="Ruinen">Ruinen</Option>
                        <Option value="Stal">Stal</Option>

                    </Select>
                </Form.Item>  }       
            </Form>       
        </Modal>
        )
}

export default AddPaardModal