import React, {useState, useRef} from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {Spin, Button, message, Modal} from 'antd'
import {EditOutlined,DeleteOutlined, ExclamationCircleOutlined, DownloadOutlined, PlusOutlined} from '@ant-design/icons'

import { gql } from 'apollo-boost';
import ProTable, {  IntlProvider, enUSIntl} from '@ant-design/pro-table';

import AdminLayout from './KalenderLayout'
const { confirm } = Modal;

export const GET_ALL_PAARDEN = gql`
query{
  allLespaardItems{
    edges{
      node{
        id
        paard{
          id
          naam
        }
      }
    }
  }
}
`

const Paarden = (props)=>{
    const {data, error, loading}=useQuery(GET_ALL_PAARDEN)
    const actionRef = useRef();

    if(loading) return (<Spin />)
    const columns = [
       {
          title: 'Paard Naam',
          dataIndex: 'naam',

            
        },
        {
          title:"Acties",
          dataIndex:'id',
          render: (value, record)=>{
          return(
            <div>
            <Button onClick={()=>{

            }}><EditOutlined /></Button> 
             <Button>
              <a href={`https://hetperhof.be/paard/${value}`} target="_blank" rel="noopener noreferrer"><DownloadOutlined  /></a>
            </Button>
            <Button onClick={()=>  confirm({
                  title: 'Wil je dit paard zeker verwijderen?',
                  icon: <ExclamationCircleOutlined />,
                  content: 'Paard zal verwijderd worden',
                  okText: 'Yes',
                  okType: 'danger',
                  cancelText: 'No',
                  onOk() {
                    },
                  onCancel() {
                    console.log('Cancel');
                  },
                })
            }>
            <DeleteOutlined /></Button>
            
            </div>
            )
          }
        }
        ]
    const rowData = data.allLespaardItems.edges.map(item=>
    ( {
          id:item.node.id,
          naam:item.node.paard.naam,
      }
      )
      )
    return(
        <AdminLayout title= "Lespaarden">
               {error && "An error occured"}
      <IntlProvider value={enUSIntl}>
      <ProTable
        search={false}
        columns={columns}
        actionRef={actionRef}
        request={params => {return {data:rowData}}}
        rowKey="id"
        dateFormatter="string"
        toolBarRender={() => [
          <Button key="3" type="primary" onClick={()=>{}}>
            <PlusOutlined />
            Voeg een Paard toe
          </Button>,
        ]}
      />     
      </IntlProvider>
   
        </AdminLayout>
        )
}

export default Paarden