
import React, {useState, useRef} from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {Spin, Button, message, Modal} from 'antd'
import { PlusOutlined} from '@ant-design/icons'

import { gql } from 'apollo-boost';
import ProTable, {  IntlProvider, enUSIntl} from '@ant-design/pro-table';

const { confirm } = Modal;


const TableView = (props)=>{
    console.log(props.columns)
    return(
             <IntlProvider value={enUSIntl}>
                  <ProTable
                    search={false}
                    columns={props.columns}
                    actionRef={props.actionRef}
                    request={params => {return {data:props.rowData}}}
                    rowKey="id"
                    dateFormatter="string"
                    toolBarRender={() => props.toolBarRender}
                  />     
      </IntlProvider>
        )
}

export default TableView