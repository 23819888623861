import React from 'react';
import Iframe from 'react-iframe'

class Content5 extends React.PureComponent {

  render() {
    return (
      <div  id="Content5_0"  className= 'home-page-wrapper content5-wrapper'>
        <div className= 'home-page content5'>
          <div className="page2-title">
            Locatie
          </div>
          <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2501.592508652642!2d4.251968315643897!3d51.17130147958137!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c38cb3f5069c25%3A0x44ae346cf69d01f6!2sPerstraat%20131%2C%209120%20Beveren!5e0!3m2!1sen!2sbe!4v1589826973462!5m2!1sen!2sbe" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0">
          </Iframe>
          </div>
      </div>
    );
  }
}

export default Content5;
