import React from 'react';
import {  Typography, Checkbox, Row, Col, List } from 'antd';
import AdminLayout from "./AdminLayout"
import {CheckOutlined} from '@ant-design/icons'
import truck1 from '../../landing/img/truck2.jpg'
import trailer from '../../landing/img/trailer.jpg'
import {isMobile} from 'react-device-detect';

const {Title} = Typography

const Extra = ()=>{
        const data = [
     "2-paards paardencamionette",
      'Rijbewijs B voldoende',
      'Ruime Zadelkamer',
      'Hengstenuitvoering',
      '3 zitplaatsen',
      'Camera bij de paarden',
      "Maximaal laadvermogen is 800kg"

    ];
    
    const data_trailer = [
        'Pegasus Humbaur 2-paards trailer',
      'Rijbewijs BE voldoende',
      'Laadvermogen tot 2000kg',
      'Ruime zadelkamer',
    'Vergrendelbaar tussenschot'

    ];
    console.log(isMobile)
    return (
    <AdminLayout
        title={
        <div className="page2-title">
            Voertuigen
        </div>
        }
        >


    <Row gutter={16}>
        <Col span={isMobile ? 24 : 12}>
            <img width="100%" src={truck1} />
        </Col>
        <Col span={isMobile ? 24 : 12}>
            <List 
            dataSource={data}
            renderItem={item => (
                    <List.Item>
                         <List.Item.Meta
                            avatar={ <CheckOutlined />}
                            title={item} />
                      
                    </List.Item>
      )}
    />
            
            
            
            
        </Col>
    </Row>
    
    <Row gutter={16}>

        <Col span={isMobile ? 24 : 12}>
            <List 
            dataSource={data_trailer}
            renderItem={item => (
                    <List.Item>
                         <List.Item.Meta
                            avatar={ <CheckOutlined />}
                            title={item} />
                      
                    </List.Item>
      )}
    />
    </Col>
        <Col span={isMobile ? 24 : 12}>
            <img width="100%" src={trailer} />
        </Col>
            
            
            
            
       
    </Row>
    </AdminLayout>
        )
}


export default Extra