import React from 'react';

import { useQuery} from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {  Spin, Select } from 'antd';
const { Option } = Select;

export const GET_ALL_PAARDEN = gql`
query{
	allLespaardItems{
    
  edges{
    node{
      id
      paard{
        id
        naam
      }
    }
  }
  }
}`

const UserSelect = (props)=>{
    const {data, loading}= useQuery(GET_ALL_PAARDEN)
    if(loading) return (<Spin />)
    const options = data.allLespaardItems.edges.map(user =>{
      return         <Option key={user.node.id} value={user.node.id}>{user.node.paard.naam} </Option>

    }
        
            
    )
    return(
        <Select 
            {...props}
            showSearch
            optionFilterProp="children"
            value={props.value}
            onChange={(value)=>props.onChange(value)}>
            {options}
        </Select>
        )
}

export default UserSelect