import React from 'react';
import { withRouter} from 'react-router-dom'
import AdminLayout from './AdminLayout'
import {Spin} from 'antd'
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {PaardFragment} from '../../fragments/PaardFragment'


export const GET_PAARD = gql`
query($id:String!){
  user(id:$id){
    id
    username
    paardSet{
      edges{
        node{
          ...PaardFragment
        }
      }
    }
  }
  }
${PaardFragment}`

const Profile = (props)=>{
  console.log(props)
    const {data, error, loading}=useQuery(GET_PAARD, {variables:{id:props.match.params.id}})
    if(loading) return (<Spin />)

    return(
      <AdminLayout
        title={data.user.username}>
        {error && "An error occured"}
 
    </AdminLayout>
        )        
        
        

}

export default withRouter(Profile)