import React, { useState } from 'react';
import {Modal, Form, Input, Button, InputNumber, Divider} from 'antd'
import {QuestionCircleTwoTone} from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import moment from 'moment';
import AllUserSelect from '../components/AllUsersSelect'
const ADD_LESGEVER = gql`
mutation($input:LesgeverMutationInput!){
  lesgever(input:$input){
    lesgever{
      id
    }
  }
}`

const ADD_LESGEVER_BY_ID = gql`
mutation ($input: LesgeverByIDInput!) {
  lesgeverFromUserID(input: $input) {
    lesgever {
      id
    }
  }
}
`


const AddCalenderModal = ({visible, clickedDate,onCancel, onOk})=>{
        const [form] = Form.useForm();
        const [addLesgever] =  useMutation(ADD_LESGEVER)
        const [addLesgeverByID] = useMutation(ADD_LESGEVER_BY_ID)
        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
          };
        const tailLayout = {
          wrapperCol: { offset: 8, span: 16 },
        };
    const onSubmit = ()=>{
        form
          .validateFields()
          .then(values => {
            console.log(values)
            addLesgever({variables:{input:values}})
            form.resetFields();
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });      
      onOk()
    }
    return(
    <Modal 
        destroyOnClose={true}
        title="Voeg een lesgever toe"
        visible={visible}
        onCancel={onCancel}
        okButtonProps={{ visible: false }}

        onOk={onSubmit}>
      <Form
        form={form}
        {...layout}
      >
        <Form.Item 
          label="Naam" 
          name="voornaam" 
          rules={[{ required: true, message: 'Geef een voornaam' }]}>
            <Input />
        </Form.Item>
        
    <Form.Item 
          label="Voornaam" 
          name="achternaam" 
          rules={[{ required: true, message: 'Geef een achternaam' }]}>
            <Input />
        </Form.Item>
    <Form.Item 
          label="Email" 
          name="email" 
          rules={[{ required: true, type: "email", message: 'Geef een achternaam' }]}>
            <Input />
        </Form.Item>
    <Form.Item 
          label="Loon" 
          name="loon">
            <InputNumber />
        </Form.Item>
      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Toevoegen
        </Button>

      </Form.Item>
    </Form>
    
    <Divider> OR</Divider>
    
    <Form
       {...layout}

    >
      <Form.Item
        label="Select gebruiker"
        name="user">
        <AllUserSelect />
      </Form.Item>
      <Form.Item 
          label="Loon" 
          name="loon">
            <InputNumber />
        </Form.Item>
  </Form>
    </Modal>
    )    
}

export default AddCalenderModal