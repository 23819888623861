import React, {useReducer}from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  
} from "react-router-dom";
import './App.css';
import Profile from './pages/profile'
import Home from './landing/landing'
import Login from './pages/login'
import PrivateRoute from './components/PrivateRoute'
import PaardAdmin from './pages/admin/Paarden'
import UserDetail from './pages/admin/UserDetail'
import UserAdmin from './pages/admin/Users'
import Facturatie from './pages/admin/Facturatie'
import FactuurItem from './pages/admin/FactuurItems'
import Kalender from './pages/admin/kalender/BigKalender'
import KalenderPaarden from './pages/admin/kalender/Paarden'
import KalenderLesgevers from './pages/admin/kalender/Lesgever'
import KalenderLeerling from './pages/admin/kalender/Leerling'
import KalenderAfrekening from './pages/admin/kalender/Afrekening'
import TableView from './pages/admin/kalender/TableView'
import LessenPerLesgever from './pages/admin/kalender/LessenPerLesgever'
import Kamp from './pages/admin/kalender/Kamp'
import Hooi from './pages/admin/Hooi'
import Gsport from './pages/admin/kalender/gsport'
import Reserveren from './pages/rental/Reserveren'
import Info from './pages/rental/Info'
import Voertuigen from './pages/rental/Voertuigen'
import Contact from "./pages/rental/contact"
import {userReducer} from './reducers/UserReducer'
export const MyContext = React.createContext();

const initialState = {
  userId:'',
  isCalendar:true,
}

function App() {
  const [state, dispatch] = useReducer(userReducer, initialState);

  return (
  <MyContext.Provider value={{state, dispatch}}>
    <Router>  
    <Switch>
  
            <Route path="/profile">
              <Profile />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <PrivateRoute path="/admin/user/:id" component={UserDetail} />
             
            <PrivateRoute path="/admin/paarden" component={PaardAdmin} />
  
            
            <PrivateRoute path="/admin/eigenaars"component ={()=> <UserAdmin isDierenarts={false}/>} />
            <PrivateRoute path="/admin/dierenarts"component ={()=> <UserAdmin isDierenarts={true}/>} />
  
            <PrivateRoute path="/admin/facturatie" component={Facturatie} />
            <PrivateRoute path="/admin/factuuritem" component={FactuurItem} />
            <PrivateRoute path="/admin/hooi" component={Hooi} />

            <PrivateRoute path="/admin/kalender/paarden" component={KalenderPaarden} />
            <PrivateRoute path="/admin/kalender/lesgevers" component={KalenderLesgevers} />
            <PrivateRoute path="/admin/kalender/leerling" component={KalenderLeerling} />
            <PrivateRoute path="/admin/kalender/afrekening" component={KalenderAfrekening} />
            <PrivateRoute path="/admin/kalender/LessenPerLesgever" component={LessenPerLesgever} />
            <PrivateRoute path="/admin/kalender/gsport" component={Gsport} />

            <PrivateRoute path="/admin/kalender/kamp" component={Kamp} />

            <PrivateRoute path="/admin/kalender/table" component={TableView} />
  
            <PrivateRoute path="/admin/kalender" component={Kalender} />
             
           {/* <Route path="/rental/reserveren" component={Reserveren} />

            <Route path="/rental/info" component={Info} />

            <Route path="/rental/voertuigen" component={Voertuigen} />
            
            <Route path="/rental/contact" component={Contact} />*/}
              <Route path="/">
              <Home />
            </Route>
          </Switch>  
    </Router>
  </MyContext.Provider>
  );
}

export default App;
