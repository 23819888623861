
import React, {useState, useRef} from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {Spin, Button, message, Modal, InputNumber} from 'antd'
import {EditOutlined,DeleteOutlined, ExclamationCircleOutlined, DownloadOutlined, PlusOutlined} from '@ant-design/icons'
import LesgeverModal from './AddLesgeverModal'
import { gql } from 'apollo-boost';
import TableView from '../../../components/TableView.js'
import AdminLayout from './KalenderLayout'
const { confirm } = Modal;

export const GET_ALL_LESGEVER = gql`

{
  allLesgeverItems {
    edges {
      node {
        id
        lesgever {
          id
          firstName
          lastName
        }
      }
    }
  }

}
`
const Paarden = (props)=>{
    const {data, error, loading}=useQuery(GET_ALL_LESGEVER)
    const [visible, setVisible]= useState(false)
    const actionRef = useRef();
    if(loading) return (<Spin />)
    const columns = [
       {
          title: 'lesgever',
          dataIndex: 'naam',

            
        },
        {
          title:"Acties",
          dataIndex:'id',
          render: (value, record)=>{
          return(
            <div>
            <Button onClick={()=>{

            }}><EditOutlined /></Button> 
            <Button onClick={()=>  confirm({
                  title: 'Wil je dit paard zeker verwijderen?',
                  icon: <ExclamationCircleOutlined />,
                  content: 'Paard zal verwijderd worden',
                  okText: 'Yes',
                  okType: 'danger',
                  cancelText: 'No',
                  onOk() {
                    },
                  onCancel() {
                    console.log('Cancel');
                  },
                })
            }>
            <DeleteOutlined /></Button>
            
            </div>
            )
          }
        }
        ]
    const rowData = data.allLesgeverItems.edges.map(item=>
    ( {
          id:item.node.id,
          naam:item.node.lesgever.firstName,
      }
      )
      )
      
    return(
        <AdminLayout>
            <TableView 
                columns= {columns}
                rowData ={rowData}
                actionRef={actionRef}
                toolBarRender ={[
                      <Button key="3" type="primary" onClick={()=>{setVisible(true)}}>
                        <PlusOutlined />
                        Voeg een lesgever toe
                      </Button>,
                    ]}
                />
            <LesgeverModal
                visible={visible}
                onOk={()=>setVisible(false)}
                onCancel={()=>setVisible(false)}
            />
        </AdminLayout>
        )
}

export default Paarden