import React from 'react';

import {  LocaleProvider, Badge, Calendar, DatePicker, Button, Typography, Space,Form, Checkbox, InputNumber,Col,  Row} from 'antd';
import nl_BE from 'antd/lib/locale-provider/nl_BE';

var moment = require('moment')
const { RangePicker } = DatePicker;
const {Title} = Typography

const listData = []

const App = ({data, setData, current, setCurrent}) => {
  const [form] = Form.useForm();

  const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};
  const checkAvailibility = (dates, value)=>{
    return (dates.reduce((accumlater, currentValue)=>{
                   return accumlater || currentValue.type===value
                }, false))
  }
  
  const checkAvailability = ((currentDate=>{
            // check if there is something happing on this data
            const dates = listData.filter(item=>item.date.isSame(currentDate, "day"))
            if(dates.length > 0){
             switch (data.period){
              case "TVM":
                return checkAvailibility(dates, "VM") || dates[0].type==="FD"
              case "TNM":
                return checkAvailibility(dates, "NM") || dates[0].type==="FD"
              case "TFD":
                return dates.length > 0
              case "T2D":
                  return dates.length > 0

              case "TWE":
                // if one of the weekend days is selected the other one should be blocked
                return currentDate.day() != 6 || currentDate.day() != 0
              default:
               return currentDate <= moment()
            } 
            }else if(currentDate <= moment()){
              return true
            }else{
              switch (data.period){
               case "TWE":
                    if(currentDate.day()==6){
                       //check if the next day is in the dates list
                       const checkWeekend = listData.filter(item=>item.date.isSame(moment(currentDate).add(1,"d"),"day"))
                       return checkWeekend.length >0
                    }else if(currentDate.day()==0){
                       const checkWeekend = listData.filter(item=>item.date.isSame(moment(currentDate).subtract(1,"d"),"day"))
                       return checkWeekend.length >0                      
                    }else{
                       return currentDate.day() != 6 && currentDate.day() != 0
                    }
                  case "TFWD":
                    return currentDate.day() != 6 && currentDate.day() != 0
                    
                  case "TFD":
                    return currentDate.day() != 1 && currentDate.day() != 2  && currentDate.day() !=3 && currentDate.day() != 4  && currentDate.day() !=5
                    
              case "T2D":
                // check if next or previous day is open
                  const previous  = listData.filter(item=>item.date.isSame(moment(currentDate).subtract(1,"d"),"day"))
                  const next  = listData.filter(item=>item.date.isSame(moment(currentDate).add(1,"d"),"day"))
                  return (previous.length > 0 && next.length >0) || currentDate.day() ==6 || currentDate.day()==0
                  
              }
                
            }

          }))
          console.log(data)
  return (
  <div>
  <Row>
    <Col span={24}>
  <LocaleProvider locale={nl_BE}>
  <Form
          form={form}
          {...formItemLayout}
          scrollToFirstError
          onFinish={(values)=>{
             console.log("hello")
             setData({...data, extraInput:values })
             setCurrent(current+1)
          }}
>
  <Form.Item
    label="Selecteer Period"
    name="date"
    rules={[
          {
            required: true,
            message: 'Gelieve een periode te kiezen',
          },
        ]}>
  {data.period === "TVM" || data.period ==="TNM" || data.period === "TFD" ||data.period=== "TFWD" ? <DatePicker 
        
          fullscreen={false}
          disabledDate = {checkAvailability}
          //onSelect={(date)=>setData({...data, fromDate:date})}
          //onSelect ={(date)=>console.log(date)}
          //dateCellRender={dateCellRender} 
          //monthCellRender={monthCellRender} 
          /> : 
          
          <RangePicker  
              disabledDate = {checkAvailability}
            //  onSelect={(date)=>setData({...data, fromDate:date})}

          
          /> }
  </Form.Item>
  <Form.Item
    name="kilometers"
    label="Verwacht aantal kilometers"
    rules={[
          {
            required: true,
            message: 'Geef een geschat aantal kilometers op!',
          },
        ]}
    tooltip="Er is steeds een aantal kilometer inbegrepen in de prijs. Voor de extra kilometers wordt er €0,35/km aangerekend."
    >
    <InputNumber />
  </Form.Item>
  
  <Form.Item
    name="hooi"
    label="Hooi Nodig?"
    valuePropName="checked"
    >
            <Checkbox> + € 10</Checkbox>
    </Form.Item>

  <Form.Item
    name="cleaning"
    label="Cleaning Nodig?"
    valuePropName="checked"
    >
             <Checkbox> + €50 </Checkbox>
    </Form.Item>
           <br/>

    </Form>
    </LocaleProvider>
      </Col>
    </Row>
    <Space wrap  style={{float:"right"}}> 
             <Button
                 onClick={()=>setCurrent(current-1)}
             >
                 Previous
             </Button>
              <Button 
                type="primary" 
                htmlType="submit"
                onClick={()=>{
                  form.submit()
                  
                }}
               >Next</Button>
          </Space>
            </div>
            
            

    )
};
export default App;