import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Modal, Input, message, Form, DatePicker, InputNumber} from 'antd';
import AllUserSelect from './components/AllUsersSelect';
import {GET_ALL_PAARDEN} from './Paarden'
import {GET_ALL_USERS} from './Users'

var moment = require('moment'); 
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ADD_PAARD= gql`
mutation ($id: ID, $naam: String!, $eigenaar: String!, $startDate:Date, $endDate:Date, $huur:Int!) {
  addPaard(input: {id: $id, naam: $naam, eigenaarID: $eigenaar, startDate:$startDate, endDate:$endDate, huur:$huur}) {
    paard {
      id
    }
    errors{
        field
        messages
    }
    }
  
}`

const AddPaardModal=({visible, onOk, onCancel, record, eigenaar})=>{
    const [form] = Form.useForm();
    const [addPaard]= useMutation(ADD_PAARD,{
    onCompleted(...params){ 
        message.success("Paard succesvol toegevoegd")
        },
      awaitRefetchQueries:true,
      refetchQueries:[{query:GET_ALL_PAARDEN}, {query:GET_ALL_USERS}],
    })

    const onFinish = async (values)=>{
        console.log(values)
        values.startDate =  values.startDate && values.startDate.format('YYYY-MM-DD')    
        values.endDate =  values.endDate && values.endDate.format('YYYY-MM-DD') 
        values.huur = values.huur ? values.huur : 0
        delete values.date
        if(record) {values.id = record.node.id}
        await addPaard({variables:values})
        onOk()
        form.resetFields();

    }
    let defaultValues = {}
    if(record){
        defaultValues ={
            naam: record.node.naam,
            eigenaar:record.node.eigenaar.id,
            startDate:record.node.startDate &&moment(record.node.startDate,'YYYY-MM-DD'),
            endDate:record.node.endDate &&moment(record.node.endDate,'YYYY-MM-DD'),
            huur:record.node.huur ? record.node.huur : 0,
        }
        form.setFieldsValue(defaultValues)
    }
    if(eigenaar){
        defaultValues ={
            eigenaar:eigenaar
            }
        form.setFieldsValue(defaultValues)        
    }
    return(
        <Modal
            title="Voeg een paard toe"
            visible={visible}
            onCancel={()=>{
                form.resetFields()
                onCancel()
                
            }}
            onOk={()=>{
                form.submit()
            }}
            >
            <Form 
                form={form}
                {...layout} 
                onFinish={onFinish}>
                <Form.Item
                    label="Paard naam"
                    name="naam"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                
                >
                    <Input />
                </Form.Item>
                
                <Form.Item
                    label="Eigenaar"
                    name="eigenaar"
                    rules={[{ required: true, message: 'Geef de eigenaar van het paard!' }]}
                
                >
                    <AllUserSelect 
                        onChange={(value)=>form.setFieldsValue({eigenaar:value})}
                        value={form.getFieldValue('eigenaar')}
                        />
                </Form.Item>
                <Form.Item
                    label="Huur"
                    name="huur"

                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    label="Eerst maand"
                    name="startDate"
                    rules={[{ required: true, message: 'Geef de startmaand op' }]}
                
                >                
                    <DatePicker  picker="month" />
                </Form.Item>

                 <Form.Item
                    label="Laatste maand"
                    name="endDate"
                >                
                    <DatePicker  picker="month" />
                </Form.Item>
            </Form>       
        </Modal>
        )
}

export default AddPaardModal