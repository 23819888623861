import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row, Col, Image } from 'antd';
import {OverPack} from 'rc-scroll-anim';
import banner from './img/banner.jpg'
import stallen from './img/stallen.jpg'
import binnenpiste from './img/binnenpiste.GIF'
import solarium from './img/wasplaats.GIF'
import wasplaats from './img/wasplaats2.GIF'
import weide from './img/weide.GIF'

class Content extends React.PureComponent {
  render() {
    return (
      <div className='home-page-wrapper content0-wrapper' >
        <div className= 'home-page content0'>

          <OverPack  playScale={ 0.3} key='test'>
          <div className="page2-title">
            Pensionstalling
          </div> 
            <QueueAnim
              type="bottom"
              key="blockInfo"
              leaveReverse
              component={Row}
            >
             <Col key='info1' className="content0-block"  md={ 8}  xs= {24}>
                  <Image src={stallen} alt="Stallen"/>
                  <div className="content0-block-title">
                    12 ruime boxen
                  </div>
             </Col>
             <Col key='info2' className="content0-block"  md={ 8}  xs= {24}>
                  <Image src={banner} alt="Weide"/>
                  <div className="content0-block-title">
                    Buitenpiste en longeercirkel
                  </div>
             </Col>
             <Col key='info3' className="content0-block"  md={ 8}  xs= {24}>
                  <Image src={binnenpiste} alt="Binnenpiste" />
                  <div className="content0-block-title">
                    Binnenpiste
                  </div>
             </Col>
             </QueueAnim>
            <QueueAnim
              type="bottom"
              key="blockInfo2"
              leaveReverse
              component={Row}
            >
             <Col key='info4' className="content0-block"  md={8}  xs={ 24} >
                  <Image src={wasplaats} alt="Wasplaats" />
                  <div className="content0-block-title">
                    Wasplaats
                  </div>
             </Col>
             <Col key='info5' className="content0-block"  md={ 8}  xs= {24}>
                  <Image src={solarium} alt="Solarium"/>
                  <div className="content0-block-title">
                    Solarium
                  </div>
             </Col>
             <Col key='info6' className="content0-block"  md={ 8}  xs= {24}>
                  <Image src={weide} alt="Weide"/>
                  <div className="content0-block-title">
                    Weide
                  </div>
             </Col>
             </QueueAnim>
          </OverPack>
        </div>
      </div>
    );
  }
}

export default Content;
