import React from 'react';

import { useQuery} from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Select, Spin } from 'antd';

const { Option } = Select;
const ALL_USERS=gql`
query($eigenaar:ID){
  allFacturen(eigenaar:$eigenaar, state:"Open", orderBy:"-datum"){
    edges{
      node{
        id
        datum
        nummer
        value
        send
      }
    }
  }}`

const UserSelect = (props)=>{
    console.log(props.eigenaar)
    const {data, loading}= useQuery(ALL_USERS, {variables:{eigenaar:props.eigenaar}})
    if(loading) return (<Spin />)
    const options = data.allFacturen.edges.map(user =>(
        <Option key={user.node.id} value={user.node.id}>{user.node.value }</Option>
        )
            
    )
    return(
        <Select 
            {...props}
            showSearch
            optionFilterProp="children"
            value={props.value}
            onChange={(value)=>props.onChange(value)}>
            {options}
        </Select>
        )
}

export default UserSelect