import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row, Col, Image } from 'antd';
import {OverPack} from 'rc-scroll-anim';
import Rivolie from './img/Rivolie.jpeg'
import logosv from './img/Logo Sport Vlaanderen.jpg'
class Content extends React.PureComponent {
  render() {
    return (
      <div className='home-page-wrapper content0-wrapper' >
        <div className= 'home-page content0'>

          <OverPack  playScale={ 0.3} key='test'>
          <div className="page2-title">
            Ponylessen
          </div> 
          <div>
            Ponylessen 't Perhof VZW organiseert op de locatie van 't Perhof individuele ponylessen voor kindjes vanaf 4. We leren alles van het poetsen tot rijden. Hiervoor hebben we een aantal kleine 
            pony's ter beschikking. <br/>
            Ons intern regelement kan je <a href="https://drive.google.com/file/d/1O1XcrSe3_1urVhUIgsQYfoqqLtW9CAPP/view?usp=drive_link" target="_blank"> hier</a>  terug vinden
          </div>
            <div className="page2-subTitle">Tarieven</div>
            <div>
            Het jaarlijks lidgeld bedraagt €40/jaar. Dit is verschuldigd vanaf de 2e les. Hierin zit ook het lidgeld en verzekering van Paardensport.Vlaanderen die geldig is tijdens alle lessen.
            <ul style={{marginLeft:"34px"}}>
              <li>Individuele ponyles 30min: €20/les</li>
             <li>Individuele ponyles 60min: €35/les</li>
            </ul> 
            </div>
            
            
            
            
            <Row>
              <Col span={16}>
              <div className="page2-subTitle">G-werking</div>
            <p>
            Voor kinderen en jongeren met specifieke noden kunnen we privélessen aanbieden aan een verminderd tarief.
            Hierbij richten we ons vooral op kinderen en jongeren met een licht tot matig verstandelijke beperking, ASS en/of GES-problematiek. 
            Door de individuele aanpak krijgt elke ruiter de kans zich op eigen tempo te ontwikkelen en te groeien. 
            
            Dankzij de steun van Sport.Vlaanderen is de kostprijs voor een G-paardrijles  €20 per uur.
            
            </p></Col>
              <Col span={8}><Image src={logosv} style={{float:'right'}}/></Col>
          </Row>
            <div className="page2-subTitle">Helpers</div>
          <div>
            <QueueAnim
              type="bottom"
              key="blockInfo"
              leaveReverse
              component={Row}
            >

             <Col key='info2' className="content0-block"  md={ 8}  xs= {24}>
                  <Image src={Rivolie} alt="Rivolie"/>
                  <div className="content0-block-title">
                    Rivolie
                  </div>
             </Col>
             <Col key='info3' className="content0-block"  md={ 8}  xs= {24}>
                  <Image src="https://perhof.s3.eu-central-1.amazonaws.com/pictures/paarden/Hector.jpg" height="400px" alt="Hector"/>
                  <div className="content0-block-title">
                    Hector
                  </div>
             </Col>
             <Col key='info4' className="content0-block"  md={ 8}  xs= {24}>
                  <Image src="https://perhof.s3.eu-central-1.amazonaws.com/pictures/paarden/IMG-20210705-WA0027.jpg" heght="400px" alt="Sara"/>
                  <div className="content0-block-title">
                    Sara
                  </div>
             </Col>
             <Col key='Dikje' className="content0-block"  md={ 8}  xs= {24}>
                  <Image src="https://perhof.s3.eu-central-1.amazonaws.com/pictures/paarden/IMG-20210919-WA0000.jpg" alt="dikje"/>
                  <div className="content0-block-title">
                    Dikje
                  </div>
                  <div className="tag-line">Een schatje</div>
             </Col>
             <Col key='Rubble' className="content0-block"  md={ 8}  xs= {24}>
                  <Image src="https://perhof.s3.eu-central-1.amazonaws.com/pictures/paarden/278570114_525384172541208_439410650044146258_n.jpg" alt="rubble"/>
 
                  <div className="content0-block-title">
                    Rubble
                  </div>
             </Col>   
             <Col key='Roos' className="content0-block"  md={ 8}  xs= {24}>
                  <Image src="https://perhof.s3.eu-central-1.amazonaws.com/pictures/paarden/roos.PNG"/>
                  <div className="content0-block-title">
                    Roos
                  </div>
             </Col> 
             </QueueAnim>
            </div>
          </OverPack>
        </div>
      </div>
    );
  }
}

export default Content;
