import React, {useContext, useState} from 'react';

import {Layout, Spin, Alert, Menu, Button, Typography, PageHeader, Row, Col} from 'antd'
import {MenuOutlined} from '@ant-design/icons'
import {isMobile} from 'react-device-detect';

import {UserOutlined} from '@ant-design/icons'
import { useQuery} from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import '../../landing/less/antMotionStyle.less';
import  {Link, withRouter} from 'react-router-dom'
import logo from '../../landing/img/logo-transparent.png'

//import {MyContext} from '../App'

const { Header, Content } = Layout;
const { Title } = Typography;

export const IS_LOGGED_IN= gql`
  query{
	isLoggedIn @client(always: true)
    me {
      isSuperuser
        id
        firstName
      }
    }
`
const Main = (props)=>{
    const {data, loading, error} = useQuery(IS_LOGGED_IN)
    const [responsive, setResponsive] = useState('')
    //const { state, dispatch } = useContext(MyContext); 
     return (
        <Layout
            style={{
                backgroundColor:'white'
            }}
        >
        <div className={`nav ${responsive}`}>
            <div className={`nav-title ${responsive}`}><img src={logo} alt="Banner" style={{height:'50px'}}/>'t Perhof</div>
            
            <div className="nav-wap">
                     <Link
                    className={`nav-list ${responsive}`}
                    to="/"
                    showHeightActive="300"
                         >
                    Home
                  </Link>
                <Link
                    className={`nav-list ${responsive}`}
                    to="/rental/voertuigen"
                    showHeightActive="300"
                         >
                    Voertuigen
                  </Link>
                <Link
                    className={`nav-list ${responsive}`}
                    to="/rental/reserveren"
                    showHeightActive="300"
                         >
                    <Button type="primary">Reserveren</Button>
                  </Link> 
                <Link
                    className={`nav-list ${responsive}`}
                    to="/rental/info"
                    showHeightActive="300"
                         >
                        Info
                  </Link>      
                <Link
                    className={`nav-list ${responsive}`}
                    to="/rental/contact"
                    showHeightActive="300"
                    >Contact
                    </Link>
                    <div className="nav-icon">
                      <MenuOutlined onClick={()=>{
                      const resp = responsive ==='' ? 'responsive' : ''
                      setResponsive(resp)
                    }} />
                    </div>
            </div>
        </div>
            <Content
              style={{
              margin: isMobile ? '0 0' :'0 20%',
              padding:  '0 24px',
              minHeight: '100vh',
              backgroundColor:'#FFF7E8',
              color:"black"
            }}
            
            >
        <PageHeader 
        {...props}/>
                {error && <Alert content={error.message} />}
                {!loading && data && data.isLoggedIn}
                    
            </Content>
        </Layout>
        )
}

export default withRouter(Main)