import React from 'react';
import TweenOne from 'rc-tween-one';
import ScrollOverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';
import {Avatar} from 'antd'
import {FacebookOutlined, InstagramOutlined} from '@ant-design/icons'
class Footer extends React.Component {
  render() {
    return (
      <div key="footer" className= 'footer1-wrapper'>
        <ScrollOverPack playScale={ 0.1} className='footer1'>
          <QueueAnim
            type="bottom"
            key="ul_footer"
            component={Row}
            leaveReverse
            className= 'home-page'
          >
    {/*    <Col key='footer_1' offset={12} xs={12}  md={6} className="block" title={null} content={null}>
          <div>
            <img src="https://zos.alipayobjects.com/rmsportal/qqaimmXZVSwAhpL.svg"/>
          </div>
          <div className="slogan">
           Pensionstallen met een hart
          </div>
        </Col>*/}
        <Col key='footer_2' xs={ 12}  md={ 6} className="block" title={null} content={null}>
            Volg ons op
            <span><a href="https://www.facebook.com/T-Perhof-109893067423656" target="_blank" rel="noopener noreferrer"><Avatar className='icon' icon={<FacebookOutlined/>} /></a></span>
            <span><a href="https://www.instagram.com/hetperhof/" rel="noopener noreferrer"><Avatar className='icon' icon={<InstagramOutlined/>} /></a> </span>
        </Col>
        <Col key='footer_2' xs={ 12}  md={ 6} className="block" title={null} content={null}>
          Pensionstallen 't Perhof:<br/>
          Van Driessche, Pieter <br/>
          Perstraat 131<br/>
          9120 Haasdonk<br/>
          BTW:BE0738792976<br/>
        </Col>   
  
        <Col key='footer_2' xs={ 12}  md={ 6} className="block" title={null} content={null}>
          Ponylessen 't Perhof VZW <br/>
          Ondernemingsnummer: 0760.764.169 <br/>
        </Col>   
        </QueueAnim>
        
          <TweenOne
            animation={{ y: '+=30', opacity: 0, type: 'from' }}
            key="copyright"
            className='copyright-wrapper'
          >
            <div className='home-page'>
              <div className= 'copyright'>
                      <span>
        ©2020 by The flow. All Rights
        Reserved
      </span>
              </div>
            </div>
          </TweenOne>
        </ScrollOverPack>
      </div>
    );
  }
}

export default Footer;
