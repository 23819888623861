import React,{useState} from 'react';
import {Steps, Button} from 'antd'
import AdminLayout from "./AdminLayout"
import CalenderComponent from './CalenderComponent'
import Gegevens from "./Gegevens"
import ProductCards from "./ProductCards"
import Bevestiging from "./bevestiging"
const {Step} = Steps
const Rental = ()=>{
    const [current, setCurrent]=useState(0)
    const [data, setData]=useState({
        period:null,
        extraInput:{
            date:null,
            kilometers:75,
            hooi: false,
            cleaning:false,
            },
        gegevens:{
            name:null,
            email:null,
            achternaam:null,
            rijbewijs:null,
            extra:null,
            phone:null,
            straat:null,
            gemeente:null
        }
    })
    const steps_items=[
        {
            title:"Periode",
            content:<ProductCards data={data} setData={setData} current={current} setCurrent={setCurrent}/>
        },
        {
            title:"Datum",
            content: <CalenderComponent data={data} setData={setData} current={current} setCurrent={setCurrent}/>

        },
                {
            title:"Gegevens",
            content:<Gegevens data={data} setData={setData} current={current} setCurrent={setCurrent}/>
          },
          {
              title:"Bevestiging",
              content:<Bevestiging data={data} setData={setData} current={current} setCurrent={setCurrent}/>
          },
          
          {
              title:"Bedankt",
              content:"Bedankt voor u reservatie. Wij bevestigen zo snel mogelijk u bestelling."
          },
        
        ]
    
        const steping_stones = steps_items.map(item=>{
            return(
                <Step 
                    title={item.title} >
                </Step>
                )
        })
    return(
        <AdminLayout
        title=    {    <div className="page2-title">
            Reserveren
        </div>}>
            <Steps 
                current={current}
            >
                 {steping_stones}
            </Steps>
            <div style={{margin:"10px"}}>
                {steps_items[current].content}
            </div>

        </AdminLayout>
        )
        
    
}
export default Rental