import React, { useState } from 'react';
import { Form, Input, Button, Radio, Select } from 'antd';
import LeerlingSelect from '../components/LeerlingSelect'
import LesgeverSelect from '../components/LesgeverSelect'
const Option = Select

var moment = require('moment'); 

const FilterForm = ({value, onChange}) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState('horizontal');
  const [month, setMonth] = useState('')
  const months = moment.monthsShort().map((item,i)=>(
      <Option value={i+1}>{item}</Option>))
  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };
  const formItemLayout =
    formLayout === 'horizontal'
      ? {
          labelCol: {
            span: 6,
          },
          wrapperCol: {
            span: 12,
          },
        }
      : null;
  const buttonItemLayout =
    formLayout === 'horizontal'
      ? {
          wrapperCol: {
            span: 14,
            offset: 4,
          },
        }
      : null;
  return (
    <>
      <Form
        {...formItemLayout}
        layout="inline"
        form={form}
        initialValues={{
          layout: formLayout,
        }}
        onValuesChange={onFormLayoutChange}
      >
        <Form.Item label="Lesgever " name="lesgever">
                    <LesgeverSelect 
                        value={value.lesgever}
                        onChange= {(lesgever)=>{
                        onChange (
                            {                   // object that we want to update
                                ...value,    // keep all other key-value pairs
                                lesgever: lesgever       // update the value of specific key
                            })  
                        }
                        }
                        /> 
        </Form.Item>
        <Form.Item label="Leerling" name="leerling">
                    <LeerlingSelect
                        value={value.leerling}
                        onChange= {(leerling)=>{
                        console.log(value)
                        onChange (
                            {                   // object that we want to update
                                ...value,    // keep all other key-value pairs
                                leerling: leerling       // update the value of specific key
                            })  
                        }
                        }
                        /> 
        </Form.Item>
        <Form.Item>
                <Select
                  style={{width:'100px'}}
                  value={value.betaald.replace('_', ' ').slice(1).toLowerCase()}
                  allowClear= {true}
                  onClear={()=>{
                    onChange({...value,'betaald':''})
                  }}
                  onChange={(value)=>{
                   onChange({...value,'betaald':value})
                  }}>
                  <Option value=''>Select Methode</Option>
                  <Option value="">Niet Betaald</Option>
                  <Option value="Cash">Cash</Option>
                  <Option value="Overschrijving">Overschrijving</Option>

                  <Option value="PayConic">PayConic</Option>
                  <Option value="Factuur">Factuur</Option>


                </Select>
        </Form.Item>
        <Form.Item label="Maand" name="maand">
            <Select
              style={{ width: 120 }} 
              value={month}
              allowClear ={ true}
              placeholder="Select Maand"  
              onChange={(value)=>setMonth(value)}
              
            >
            {months}
          </Select>
          </Form.Item>
      </Form>
    </>
  );
};

export default FilterForm