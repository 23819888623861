import React, {useState, useEffect} from 'react'
import AWS from 'aws-sdk';
import { Image } from 'antd';

const Elements = ()=>{
    
    const [photos, setPhotos] = useState([])
    useEffect(()=>{
        // Initialize the Amazon Cognito credentials provider
        AWS.config.region = 'us-east-1'; // Region
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: 'us-east-1:98431cd8-0033-4c7f-b286-2dd5622087ed',
        });      
        // Create a new service object
    var s3 = new AWS.S3({
      //apiVersion: '2006-03-01',
      params: {Bucket: 'perhof'}
    });
    var albumPhotosKey = 'pictures/fotoswebsitekamp/'
    s3.listObjects({Prefix: albumPhotosKey}, function(err, data) {
    if (err) {
      return ('There was an error viewing your album: ' + err.message);
    }
    // 'this' references the AWS.Request instance that represents the response
    var href = this.request.httpRequest.endpoint.href;
    var bucketUrl = href + 'perhof' + '/';
    setPhotos (data.Contents.map(function(photo) {
      var photoKey = photo.Key;
      var photoUrl = bucketUrl + photoKey;
      return (
        <Image 
        
            width='200px' 
            src={photoUrl}
            preview={"hello"}/>
        
        )
    }));
            })
    })
    console.log(photos)
        return (<div style={{marginTop:'10px'}}>
        
        <Image.PreviewGroup>

        {photos}
      </Image.PreviewGroup>

        </div>)

}

export default Elements