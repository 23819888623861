import React, {useState, useRef} from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {Spin, Button, message, Modal} from 'antd'
import {EditOutlined,DeleteOutlined, ExclamationCircleOutlined, DownloadOutlined, PlusOutlined} from '@ant-design/icons'
import LeerlingModal from './LeerlingModal'
import { gql } from 'apollo-boost';
import ProTable, {  IntlProvider, enUSIntl} from '@ant-design/pro-table';

import AdminLayout from './KalenderLayout'
const { confirm } = Modal;

export const GET_ALL_LEERLINGEN = gql`
query{
  allKampItems{
    edges{
      node{
        id
        prijs
        startDate
        endDate
      }
    }
  }
}
`

const Paarden = (props)=>{
    const {data, error, loading}=useQuery(GET_ALL_LEERLINGEN)
    const [visible, setVisible] = useState(false)
    const actionRef = useRef();
    const columns = [
       {
          title: 'Start',
          dataIndex: 'startDate',

        },
       {
          title: 'einde',
          dataIndex: 'endDate',

        },
        ]
    const rowData = !loading && data.allKampItems.edges.map(item=>
    ( {
          id:item.node.id,
          startDate:item.node.startDate,
          endDate:item.node.endDate,

      }
      )
      )
      if (!loading && actionRef.current) {
        actionRef.current.reload();
      }
    return(
        <AdminLayout title= "Kamp">
               {error && "An error occured"}
      <IntlProvider value={enUSIntl}>
      {loading && <Spin />}

      <ProTable
        search={false}
        columns={columns}
        actionRef={actionRef}
        request={params => {return {data:rowData}}}
        rowKey="id"
        dateFormatter="string"
        toolBarRender={() => [
          <Button key="3" type="primary" onClick={()=>{setVisible(true)}}>
            <PlusOutlined />
            Voeg een leerling toe
          </Button>,
        ]}
      />     
      </IntlProvider>
        </AdminLayout>

        )
}

export default Paarden