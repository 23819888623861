import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { Modal, Input, message, Form, DatePicker, InputNumber, Switch } from 'antd';
import {GET_ALL_LEERLINGEN} from './Leerling'

var moment = require('moment'); 
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const ADD_LEERLING= gql`
mutation ($id: ID, $naamOuder:String, $achternaam: String!, $voornaam: String!, $isGSport:Boolean, $bridleId: String) {
  leerling(id: $id, achternaam: $achternaam, voornaam: $voornaam, naamOuder:$naamOuder,isGSport:$isGSport,  bridleId: $bridleId) {
    leerling {
      id
    }
  }
}`

const LeerlingModal=({visible, onOk, onCancel, record})=>{
    const [form] = Form.useForm();
    const [addLeerling]= useMutation(ADD_LEERLING,{
    onCompleted(...params){ 
        message.success("Leerling succesvol toegevoegd")
        },
      awaitRefetchQueries:true,
      refetchQueries:[{query:GET_ALL_LEERLINGEN}],
    })

    const onFinish = async (values)=>{
        if(record) {values.id = record.node.id}
        await addLeerling({variables:values})
        onOk()
        form.resetFields();

    }
    let defaultValues = {}
    console.log(record)
    if(record){
        defaultValues ={
          id:record.node.id,
          voornaam:record.node.leerling.firstName,
          achternaam:record.node.leerling.lastName,
          bridleId:record.node.bridleId,
          naamOuder:record.node.naamOuder,
          isGSport:record.node.isGSport,
        }
        form.setFieldsValue(defaultValues)
    }
    
    return(
        <Modal
            title="Voeg een leerling toe"
            visible={visible}
            onCancel={()=>{
                form.resetFields()
                onCancel()
                
            }}
            onOk={()=>{
                form.submit()
            }}
            >
            <Form 
                form={form}
                {...layout} 
                onFinish={onFinish}>
                <Form.Item
                    label="Voornaam"
                    name="voornaam"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                
                >
                    <Input />
                </Form.Item>
                
                <Form.Item
                    label="Achternaam"
                    name="achternaam"
                    rules={[{ required: true, message: 'Geef de eigenaar van het paard!' }]}
                
                >
                        <Input />
                </Form.Item>
                <Form.Item
                    label="Ouder"
                    name="naamOuder"

                >
                        <Input />
                </Form.Item>                
                <Form.Item
                    label="bridle ID"
                    name="bridleId"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="isGSport"
                    name="isGSport"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
             </Form>       
        </Modal>
        )
}

export default LeerlingModal