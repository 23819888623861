import React from 'react';
import {EditOutlined,DeleteOutlined, ExclamationCircleOutlined} from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {GET_ALL_KALENDERITEMS, DELETE_KALENDER_ITEM_AFTER} from './query'

import {Modal, Button, Descriptions, message, Select} from 'antd'

const Option = Select
var moment = require('moment'); 

export const DELETE_KALENDER_ITEM = gql`
mutation ($id: String!) {
  deleteKalenderItem(id: $id) {
    deleted
  }
}`
const UPDATE_BETAALD= gql`
mutation($id:ID!, $betaald:String!){
  kalenderItemBetaald(id:$id, betaald:$betaald){
    ok
  }
}`
const OpenChoices = ({visible, onOk, onCancel, event, onEditItem, refetch, onEditSeries})=>{
    const [deleteKalenderItem, {error}]= useMutation(DELETE_KALENDER_ITEM,{
    onCompleted(...params){ 
        message.error("Kalenderitem verwijderd")
        },
      awaitRefetchQueries:true,
      refetchQueries:[{query:GET_ALL_KALENDERITEMS}],
    })
    const [updateBetaald, {error:betaaldError}]= useMutation(UPDATE_BETAALD,{
    onCompleted(...params){ 
        message.success("Betaling geupdate")
        },
      awaitRefetchQueries:true,
      refetchQueries:[{query:GET_ALL_KALENDERITEMS}],
    })
    const [deleteSeries, {error:deleteSeriesError}]= useMutation(DELETE_KALENDER_ITEM_AFTER,{
    onCompleted(...params){ 
        message.error("Items deleted")
        },
      awaitRefetchQueries:true,
      refetchQueries:[{query:GET_ALL_KALENDERITEMS}],
    })
    return (
    
        <Modal
            visible={visible}
            title ={event &&event.node.leerling.leerling.firstName}
            onCancel={onCancel}
            footer ={[ 
                    <Button onClick={onEditItem}><EditOutlined />Item</Button>,
                    <Button onClick={onEditSeries}><EditOutlined />Series</Button>,
                    <Button danger onClick={()=>{
                      deleteKalenderItem({variables:{id:event.node.id}})
                      refetch()
                      onCancel()
                    }}><DeleteOutlined /></Button>,
                    <Button danger onClick={()=>{
                      deleteSeries({variables:{id:event.node.event.id, after:event.node.startDate}})
                      onCancel()
                      refetch()
                    }}
                    
                    ><DeleteOutlined />Series</Button>,
                    <Button onClick={onCancel}>Cancel</Button>

           ]  }  
        >
        {error && error.message}
          <Descriptions size='small'>
              <Descriptions.Item label="Leerling" span={3}>{event && event.node.leerling.leerling.firstName}</Descriptions.Item>
             {event && event.node.lesgever &&  <Descriptions.Item label="Lesgever" span={3}>{event.node.lesgever.lesgever.firstName}</Descriptions.Item>}
            <Descriptions.Item label="Start Tijd" span={3}>{event &&moment(event.node.startDate).format("LLL")}</Descriptions.Item>
            <Descriptions.Item label="Einde" span={3}>{event &&moment(event.node.endDate).format("LLL")}</Descriptions.Item>

              <Descriptions.Item label="Betaald">
                <Select
                  style={{width:'100px'}}
                  value={event && event.node.betaald.charAt(0) + event.node.betaald.replace('_', ' ').slice(1).toLowerCase()}
                  onChange={(value)=>{
                  updateBetaald({variables:{id:event.node.id, betaald:value}})
                    
                  }}>
                  <Option value="">Niet Betaald</Option>
                  <Option value="Cash">Cash</Option>
                  <Option value="Overschrijving">Overschrijving</Option>

                  <Option value="PayConic">PayConic</Option>
                  <Option value="Factuur">Factuur</Option>


                </Select>
                
              </Descriptions.Item>


            </Descriptions>
        </Modal>
        )
}

export default OpenChoices