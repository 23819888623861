import React, {useState, useRef} from 'react';
import {DownloadOutlined, CheckCircleOutlined, MailOutlined} from '@ant-design/icons'
import { withRouter} from 'react-router-dom'
import AdminLayout from './AdminLayout'
import { PlusOutlined } from '@ant-design/icons';
import {Spin, Button, message,  Form, Select } from 'antd'
import ProTable, {  IntlProvider, enUSIntl} from '@ant-design/pro-table';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import AddFactuurModal  from './AddFactuurModal'

import AllUserSelect from './components/AllUsersSelect'
const Option = Select
var moment = require('moment'); 

export const GET_ALL_INVOICES = gql`
query($eigenaar:ID, $month:Float, $year:Float){
  allFacturen(eigenaar:$eigenaar, month:$month,year:$year){
    edges{
      node{
        id
        nummer
        datum
        state
        totaal
        send
        eigenaar{
          id
          username
        }
      	
      }
    }
  }
}`

export const CREATE_INVOICES = gql`
mutation{
  createAllCurrentInvoices{
    boolean
  }
}`

export const PUBLISH_INVOICE = gql`
mutation($id:ID!){
  publishFactuur(id:$id){
    factuur{
      id
    }
  }
}
`
export const SEND_INVOICE = gql`
mutation($id:ID!){
  sendFactuur(id:$id){
    factuur{
      id
    }
  }
}
`

const Profile = (props)=>{
    const [month, setMonth]= useState(parseInt(moment().format('M')))
    const [year, setYear]= useState(moment().year())
    const [visible, setVisible]= useState(false)
    const [eigenaar, setEigenaar]= useState('')
    const {data, error, loading}=useQuery(GET_ALL_INVOICES,{ variables:{month:month, year:year, eigenaar:eigenaar}})
    const actionRef = useRef();
    
    const [createInvoices] = useMutation(CREATE_INVOICES,{
    onCompleted(...params){ 
      if (actionRef.current) {
        actionRef.current.reload();
      }
        message.success("Invoices zijn aangemaakt")
        },
      awaitRefetchQueries:true,
      refetchQueries:[{query:GET_ALL_INVOICES,  variables:{month:month, year:year, eigenaar:eigenaar}}],
    })
    const [publishFactuur] = useMutation(PUBLISH_INVOICE,{
    onCompleted(...params){ 
      if (actionRef.current) {
        actionRef.current.reload();
      }
        message.success("Factuur is klaar")
        },
      awaitRefetchQueries:true,
      refetchQueries:[{query:GET_ALL_INVOICES,  variables:{month:month, year:year, eigenaar:eigenaar}}],
    })
    const [sendFactuur] = useMutation(SEND_INVOICE,{
    onCompleted(...params){ 
      if (actionRef.current) {
        actionRef.current.reload();
      }
        message.success("Factuur is klaar")
        },
      awaitRefetchQueries:true,
      refetchQueries:[{query:GET_ALL_INVOICES,  variables:{month:month, year:year, eigenaar:eigenaar}}],
    })

    if(loading) return (<Spin />)
    const columns = [
       {
          title: 'Factuur',
          dataIndex: 'nummer',
          sorter: (a, b) =>  a.nummer < b.nummer ? -1 : 1,
            
        },
        {
          title: 'Eigenaar',
          dataIndex: 'eigenaar',
          sorter: (a, b) =>  a.eigenaar.username < b.eigenaar.username ? -1 : 1,
          render: (value, record)=>{
            return(
              <div>
              {value.username}
              </div>
              )
          }
        },
        {
          title: 'Datum',
          dataIndex: 'datum',
          
          render: (value)=>{
            return value ? moment(value,'YYYY-MM-DD').format("MMM YYYY") : '-'
          }
        },
        {
            title:'Status',
            dataIndex:'state',
        },
         {
            title:'Totaal',
            dataIndex:'totaal',
            sorter: (a, b) =>  a.totaal < b.totaal ? -1 : 1,
        },
        {
          title:"Acties",
          dataIndex:'id',
          render: (value, record)=>{
          return(
            <div>
            <Button>
              <a href={`https://hetperhof.be/factuur/${value}`} target="_blank" rel="noopener noreferrer"><DownloadOutlined  /></a>
            </Button>
           {record.state === 'Open' ? <Button onClick={()=>publishFactuur({variables:{id:record.id}})}>
              <CheckCircleOutlined />
            </Button>  :
            !record.send  && <Button onClick={()=>sendFactuur({variables:{id:record.id}})}>
              <MailOutlined />
            </Button>  }           
            
            
            
            </div>
            )
          }
        }
        ]
    const rowData = data.allFacturen.edges.map(item=>
    ( {
          id:item.node.id,
          eigenaar:item.node.eigenaar,
          nummer : item.node.nummer,
          datum:item.node.datum,
          state:item.node.state,
          totaal: item.node.totaal,
          send:item.node.send,
      }
      )
      )
    const months = moment.monthsShort().map((item,i)=>(
      <Option value={i+1}>{item}</Option>))

    return(
      <AdminLayout
        title="Facturatie">
        <Form
          layout="inline"
          >
          <Form.Item>
            <Select
              style={{ width: 120 }} 
              value={month}
              allowClear ={ true}
              placeholder="Select Maand"  
              onChange={(value)=>setMonth(value)}
            >
            {months}
          </Select>
          </Form.Item>
          <Form.Item style={{ width: 120 }} >
            <Select 
                allowClear ={ true}
                onChange={(value)=> setYear(value)}
                value={year}
                placeholder="Selecteer jaar"
            style={{ width: 120 }} >
                <Option value={2023}>2023</Option>
               <Option value={2022}>2022</Option>
               <Option value={2021}>2021</Option>
              <Option value={2020}>2020</Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <AllUserSelect 
              allowClear
              onChange = {(value)=>setEigenaar(value)}
              placeholder = {"Selecteer Eigenaar"}
              value={eigenaar ? eigenaar : undefined}
            style={{ width: 150 }}  />
          </Form.Item>
        </Form>
        {error && "Oeps er ging iets fout"}
        <Button onClick={()=>createInvoices()}>Maak facturen van deze maand</Button>
        <IntlProvider value={enUSIntl}>
          <ProTable
        search={false}
        columns={columns}
        actionRef={actionRef}
        request={params => {return {data:rowData}}}
        rowKey="id"
        dateFormatter="string"
        toolBarRender={() => [
          <Button key="1" type="primary" onClick={()=>{setVisible(true)}}>
            <PlusOutlined />
            Voeg een factuur toe
          </Button>,]}
      />
           <AddFactuurModal 
        refetchQueries ={ [{query:GET_ALL_INVOICES,  variables:{month:month, year:year, eigenaar:eigenaar}}]}
        visible={visible}
        onOk={()=>{
            setVisible(false)
            if (actionRef.current) {
              actionRef.current.reload();
            }

        }
        }
        onCancel={()=>{
          setVisible(false)

        }}
      />
      </IntlProvider>
    </AdminLayout>
        )        
        
        

}

export default withRouter(Profile)