import React from "react";
import {Route, Redirect} from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks';

import gql from 'graphql-tag';

export const IS_LOGGED_IN= gql`
 query{
  isLoggedIn @client(always: true)
 }`

const GET_USER_DETAILS = gql`
query($id:String!){
  user(id:$id){
    id
    isSuperuser
    firstName
    
  }
}`
const PrivateRoute= ({ component: Component, ...rest })=> {
  const {loading, data}  = useQuery(IS_LOGGED_IN)
  if(loading) return 'loading'
  console.log(data.isLoggedIn ? true :false)
  return (
    <Route
      {...rest}
      render={props =>
        data.isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login/",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
    }
    

export default PrivateRoute