import React, {useState, useRef} from 'react';
import ProTable, {  IntlProvider, enUSIntl} from '@ant-design/pro-table';
import {Spin, Button, message, Modal, Tag} from 'antd'
import {EditOutlined,DeleteOutlined, ExclamationCircleOutlined, RightCircleOutlined, PlusOutlined, LeftCircleOutlined} from '@ant-design/icons'
import { useMutation } from '@apollo/react-hooks';
import {GET_ALL_KALENDERITEMS, DELETE_KALENDER_ITEM_AFTER} from './query'
import FilterForm from './FilterForm'
import {DELETE_KALENDER_ITEM} from './OpenChoices'
import moment from 'moment'
import 'moment/locale/nl'  // without this line it didn't work

moment.locale("nl")
const { confirm } = Modal;

const TableView = ({data, date, setModalVisible,setChoicesVisible, setEvent, setDate, actionRef})=>{
    const [deleteKalenderItem, {error}]= useMutation(DELETE_KALENDER_ITEM,{
    onCompleted(...params){ 
        message.error("Kalenderitem verwijderd")
        },
      awaitRefetchQueries:true,
      refetchQueries:[{query:GET_ALL_KALENDERITEMS}],
    })
    const columns = [
       {
          title: 'datum',
          dataIndex: 'startDate',
          render: (value)=>{
            
            return moment(value).format('L')
          },
          defaultSortOrder: 'ascend',

          sorter: (a,b)=>{
            return new Date(a.startDate) - new Date(b.startDate)
          }
            
        },
       {
          title: 'van',
          dataIndex: 'startDate',
          render: (value)=>{
            return moment(value).format('hh:mm')
          }
            
        },
       {
          title: 'duur',
          dataIndex: 'endDate',
          render: (value, record)=>{
            return moment.duration(moment(record.endDate).diff(record.startDate)).asMinutes()
          }
            
        },
       {
          title: 'Leerling',
          dataIndex: 'leerling',
          render: (value)=>{

            return value.leerling ? value.leerling.firstName : "Naam"
          }
            
        },
       {
          title: 'lesgever',
          dataIndex: 'lesgever',
          
          render: (value)=>{
            return  value.lesgever ? <Tag color={value.color}>{value.lesgever.firstName}</Tag> : "Geen lesgeve"
          }
            
        },
               {
          title: 'paard',
          dataIndex: 'paard',
          
          render: (value)=>{
            return  value.paard ? value.paard.naam :'Geen Paard'
          }
            
        },
       {
          title: 'Betaald',
          dataIndex: 'betaald',
          sorter:(a,b)=>{
          return a.betaald.localeCompare( b.betaald)
          },
          filters: [
            {
              text: 'Cash',
              value: 'CASH',
            },
            {
              text: 'Payconic',
              value: 'PAYCONIC',
            },
                        {
              text: 'Niet Betaald',
              value: 'NIET_BETAALD',
            },
                                    {
              text: 'Factuur',
              value: 'FACTUUR',
            },
                                    {
              text: 'Overschrijving',
              value: 'OVERSCHRIJVING',
            },
            ],
                onFilter: (value, record) => record.betaald.indexOf(value) === 0,

        },
       {
          title: 'Prijs',
          dataIndex: 'prijs',
        },
         {
          title: 'afrekening',
          dataIndex: 'afrekening',
          
          render: (value)=>{
            return  value.afrekening ? value.afrekening.nummer :''
          }
            
        },
        {
          title:"opties",
          dataIndex:'id',
          render: (value, record)=>{
          return(
            <div>
            <Button onClick={()=>{
                setChoicesVisible(true)
                setEvent(record.id)
            }}><EditOutlined /></Button> 
            <Button onClick={()=>  confirm({
                  title: 'Wil je dit paard zeker verwijderen?',
                  icon: <ExclamationCircleOutlined />,
                  content: 'Paard zal verwijderd worden',
                  okText: 'Yes',
                  okType: 'danger',
                  cancelText: 'No',
                  onOk() {
                  deleteKalenderItem({variables:{id:record.id}})
                    },
                  onCancel() {
                    console.log('Cancel');
                  },
                })
            }>
            <DeleteOutlined /></Button>
            
            </div>
            )
          }
        }
        ]
    const headerTitle = <div>
      <Button 
        icon={<LeftCircleOutlined />}
        onClick = {()=>{
            setDate(moment(date).subtract(1, 'months').toDate())
            actionRef.current.reload();
        }        
              
        }
        /> { moment(date).format("MMMM") }        <Button 
        icon={<RightCircleOutlined />}
        onClick = {()=>{
          setDate(moment(date).add(1, 'months').toDate())
          actionRef.current.reload();

          
        }
          
        }
        /></div>
    return(
      <IntlProvider value={enUSIntl}>
      <ProTable
        headerTitle = {headerTitle}
        search={false}
        columns={columns}
        actionRef={actionRef}
        request={params => {return {data:data}}}
        rowKey="id"
        dateFormatter="string"
        toolBarRender={() => [
          <Button key="3" type="primary" onClick={()=>{}}>
            <PlusOutlined />
              Nieuw item
          </Button>,
        ]}
      />     
      </IntlProvider>)
      
}

export default TableView